import React from 'react';
import ButtonLoading from 'src/components/button-loading';
import { projectAPIs } from 'src/services';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function DownloadBadgeButton({ projectUuid, userUuid, disabled }) {
  const handleDownloadBadge = async () => {
    try {
      const response = await projectAPIs.generateBadge(projectUuid, userUuid);
      const blobURL = window.URL.createObjectURL(response);
      window.open(blobURL, '_blank').print();
      // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
      //   images: [{ src: blobURL, blob: response }],
      //   userUuid: userUuid,
      //   projectUuid: projectUuid,
      // });
      createNotification('Загрузка прошла успешно', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  if (!projectUuid || !userUuid) return null;
  return (
    <ButtonLoading
      variant="outlined"
      disabled={disabled}
      onClick={() => {
        eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
          content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
          onOk: handleDownloadBadge,
        });
      }}
    >
      Скачать бейдж
    </ButtonLoading>
  );
}

export default DownloadBadgeButton;
