import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

function ActionsButton({ options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        sx={{
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              marginRight: '8px',
            },
          },
        }}
        onClose={handleClose}
      >
        {options.map((option, idx) =>
          option.isHidden ? null : (
            <MenuItem
              key={idx}
              color={option.color}
              disabled={option.disabled}
              sx={{ textTransform: 'capitalize' }}
              onClick={() => {
                if (option.onClick && typeof option.onClick === 'function') {
                  option.onClick();
                }
                handleClose();
              }}
            >
              {option.label}
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>
  );
}

export default ActionsButton;
