import { Button, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import Loader from 'src/components/loader';
import { accreditationStatuses, isBezviz, noVisaStatuses, routes } from 'src/constants';
import { useProjectDetail } from 'src/hooks';
import { useAbility } from 'src/hooks/useAbility';
import UserIcon from 'src/layouts/components/UserIcon';
import UserLayout from 'src/layouts/UserLayout';
import { downloadApplicationList, getApplicationList, getTotalCounts, setProject } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { ORDER_DIRECTION } from 'src/utils/constant';
import { request } from 'src/utils/request';
import ReportButton from './ReportButton';
import UserList from './UserList';

function ProjectsUsersPage() {
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const ability = useAbility();

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isInitTotal, setIsInitTotal] = useState(false);
  const [sortState, setSortState] = useState({
    sortOrder: ORDER_DIRECTION.ASC,
    sortBy: '',
  });

  const user = useSelector((state) => state.auth.user);
  const currentProject = useSelector((state) => state.application.currentProject);
  const counts = useSelector((state) => state.application.counts);

  const projectUuid = params.uuid;
  const queryParams = qs.parse(location.search);
  const statuses = isBezviz(user) ? noVisaStatuses : accreditationStatuses;
  const activeTab = queryParams.tab || statuses.processing.name;

  const { isLoading, data } = useProjectDetail({ projectUuid: projectUuid });

  const handleGetApplicationList = useCallback(() => {
    return getApplicationList({
      ...queryParams,
      page: queryParams.page,
      sortOrder: (queryParams.sortOrder ?? '').toUpperCase(),
    });
  }, [isInitTotal, location, currentProject]);

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projectsUsers.path.replace(':uuid', projectUuid)}?${query}`);
    },
    [queryParams]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
      getTotalCounts({ projectUuid: data?.uuid, isBezviz: isBezviz(user) }).then(() => setIsInitTotal(true));
    }
  }, [isLoading, data]);

  const handleSelectTab = (name) => () => {
    setQueryParams({ tab: name, page: 1 });
    setSortState({ sortOrder: ORDER_DIRECTION.ASC, sortBy: '' });
  };

  const downloadList = useCallback(async () => {
    return await downloadApplicationList(queryParams);
  }, [location]);

  useEffect(() => {
    if (isInitTotal) {
      setIsLoadingTable(true);
      handleGetApplicationList().then(() => setIsLoadingTable(false));
    }
  }, [handleGetApplicationList]);

  const tabs = Object.values(statuses).map((s) => ({
    label: s.label,
    icon: s.icon,
    count: counts[s.name],
    isActive: activeTab === s.name,
    onSelect: handleSelectTab(s.name),
  }));

  const handleRequestSort = (property) => {
    const { sortBy, sortOrder } = sortState;
    const isAsc = sortBy === property && sortOrder === ORDER_DIRECTION.ASC;
    const isDesc = sortBy === property && sortOrder === ORDER_DIRECTION.DESC;
    setSortState({
      sortOrder: isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC,
      sortBy: property,
    });
  };

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack gap="20px">
              <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h6">Список участников</Typography>
                {ability.can(ACCESSES.DOWNLOAD_REPORT) && (
                  <ReportButton onDownload={downloadList} disabled={isLoading} />
                )}
              </Stack>
              <Stack direction="row" gap="8px" flexWrap="wrap">
                {tabs.map((tab, i) => (
                  <Button
                    key={`tab-${i}`}
                    variant={tab.isActive ? 'contained' : 'text'}
                    color={tab.isActive ? 'primary' : 'secondary'}
                    startIcon={<UserIcon icon={tab.icon} />}
                    onClick={tab.onSelect}
                  >
                    {tab.label} ({tab.count || 0})
                  </Button>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        {isInitTotal ? (
          <Card>
            <UserList
              projectUuid={projectUuid}
              loading={isLoadingTable}
              isBezviz={isBezviz(user)}
              statuses={statuses}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              sortState={sortState}
              setSortState={setSortState}
              onRequestSort={handleRequestSort}
              onReloadTable={() => {
                handleGetApplicationList();
                getTotalCounts({ projectUuid: data?.uuid, isBezviz: isBezviz(user) });
              }}
            />
          </Card>
        ) : (
          <Loader />
        )}
      </Stack>

      <ConfirmModal />
    </UserLayout>
  );
}

export default ProjectsUsersPage;
