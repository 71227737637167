import { AttachFile, CheckOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { createNotification } from 'src/utils/notifications';
import { DOCUMENT_TYPES, EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { request } from '../../utils/request';
import Loader from '../loader';

function AnketaUploadField({ user, docUuid, fieldControl, userUuid, disabled, name, isResizable }) {
  const currentProject = useSelector((state) => state.application.currentProject);
  const { localizeText } = useLanguage();
  const [currentFile, setCurrentFile] = useState('');
  const [showImage, setShowImage] = useState(false);

  const fetchAnketaFile = async () => {
    const resBlob = await request(`/internal/documents/${docUuid}`, { method: 'GET' }, false, true);
    setCurrentFile(resBlob);
    return resBlob;
  };

  const { isLoading, isFetching, refetch } = useQuery(['fetchAnketaFile' + name, docUuid], fetchAnketaFile, {
    enabled: !!docUuid && !!showImage,
  });

  const handleFileChange = async (fileDocument, callback = () => {}) => {
    try {
      let body = new FormData();
      body.append('fileDocument', fileDocument);
      body.append('type', 'document_anketa');
      const res = await request(`/internal/users/${userUuid}/documents`, { method: 'PUT', body });
      callback(res.message);
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  const userAvatar = useMemo(() => {
    return user.avatarFile;
  }, [user]);

  return (
    <Stack gap="8px" marginY="4px">
      {isLoading || isFetching ? (
        <Box sx={{ width: 'fit-content' }}>
          <Loader size="20px" />
        </Box>
      ) : docUuid ? (
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{ marginY: '8px', cursor: 'pointer', textDecoration: 'underline', color: '#4ECC0A' }}
            onClick={async () => {
              setShowImage(true);
              const resBlob = currentFile ? { data: currentFile } : await refetch();
              const fileURL = URL.createObjectURL(resBlob.data);
              if (resBlob.data?.type?.includes('pdf')) {
                eventBus.emit(EVENTS.OPEN_VIEW_PDF_MODAL, {
                  images: [{ src: fileURL }],
                });
              } else {
                eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, {
                  images: [{ src: fileURL }],
                  fileName: 'document-anketa.png',
                });
              }
            }}
          >
            {localizeText.UPLOADED}
          </Typography>
          <CheckOutlined />
        </Stack>
      ) : null}
      <Stack direction="row" gap="8px">
        <Button
          startIcon={<AttachFile />}
          variant="contained"
          component="label"
          sx={{ width: 'fit-content' }}
          disabled={isLoading || disabled}
          onClick={() => {
            eventBus.emit(EVENTS.OPEN_IMAGE_CROPPER_MODAL, {
              isResizable,
              onOk: async (file) =>
                await handleFileChange(file, (document) => {
                  fieldControl.onChange(document.uuid);
                }),
              extraUpload:
                isResizable && userAvatar
                  ? {
                      text: localizeText.USE_AVATAR,
                      file: new File([userAvatar], 'avatar'),
                      onOk: async (file) => {
                        setCurrentFile(new Blob([file], { type: file.type }));
                        const response = await userAPIs.getUserDocuments(user.uuid, {
                          type: DOCUMENT_TYPES.AVATAR,
                          projectUuid: currentProject?.uuid,
                        });
                        fieldControl.onChange(response.message?.[0]?.uuid);
                      },
                    }
                  : null,
            });
          }}
        >
          {localizeText.UPLOAD}
        </Button>
        {/* {!disabled && userAvatar && (
          <Button type="button" variant="text" disabled={isLoading} onClick={handleUseAvatar}>
            Использовать аватар
          </Button>
        )} */}
      </Stack>
    </Stack>
  );
}

export default AnketaUploadField;
