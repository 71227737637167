import { ModeEdit } from '@mui/icons-material';
import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnketaForm } from 'src/components/anketa';
import Loader from 'src/components/loader';
import { UserExpertForm } from 'src/components/user-expert-form';
import { UserForm } from 'src/components/user-form';
import { useLanguage } from 'src/hooks';
import { generalAPIs } from 'src/services';
import { editUser } from 'src/store/actions/application';
import eventBus, { EVENTS } from 'src/utils/eventBus';

function AccreaditionSection({ avatarFile }) {
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const currentProject = useSelector((state) => state.application.currentProject);
  const roles = useSelector((state) => state.application.roles);

  const { localizeText, localizeMessage } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshRole, setIsRefreshRole] = useState(false);
  const [autoFillTemplate, setAutoFillTemplate] = useState({});

  const projectUuid = currentProject?.uuid;
  const projectSettings = profileInfo.projectSettings?.[currentProject?.uuid];
  const isLocked = projectSettings?.isLocked;
  const mainRoleId = currentProject ? projectSettings?.mainRoleId : profileInfo.mainRoleId;
  const role = roles.find((role) => role.id === mainRoleId);
  const isExpertAccrediation = role?.id === '10f1dc88-ed36-04ba-193b-14da3d63ade9';

  //TODO: https://trello.com/c/wlXJIg7p/195-anketa-for-industry-project
  const isSpecialProject = currentProject?.uuid === '8c20b242-4fcd-1c9a-4fb0-e947ddbb2327';

  const handleReloadProfile = () => {
    setIsRefreshRole(true);
    eventBus.emit(EVENTS.RELOAD_PROFILE);
    setTimeout(() => {
      setIsRefreshRole(false);
    }, 500);
  };

  const handleForceEdit = (memberUuid) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: localizeText.CONFIRM,
      content: `${localizeMessage.FORCE_EDIT_CONFIRM}`,
      onOk: async () => {
        await editUser({
          uuid: memberUuid,
          ...{ forceEdit: true, currentProjectUuid: projectUuid },
        });
        handleReloadProfile();
      },
    });
  };

  const fetchAutoFillFields = async () => {
    try {
      const response = await generalAPIs.getAutoFillFields();
      setAutoFillTemplate(response.message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchAutoFillFields()]);
      setIsLoading(false);
    };

    if (currentProject && profileInfo) {
      fetchData();
    }
  }, [profileInfo, currentProject]);

  return (
    <Card>
      <CardContent>
        <Stack gap="12px" sx={{ position: 'relative' }}>
          <Typography variant="h6">Регистрация</Typography>
          {isLocked && (
            <IconButton
              color="primary"
              sx={{ position: 'absolute', border: '1px solid', borderRadius: '4px', right: 0 }}
              onClick={() => handleForceEdit(profileInfo?.uuid)}
            >
              <ModeEdit />
            </IconButton>
          )}
          {isRefreshRole || isLoading ? (
            <Loader />
          ) : (
            <>
              {isSpecialProject ? (
                <>
                  {isExpertAccrediation ? (
                    <UserExpertForm
                      user={profileInfo}
                      isLocked={isLocked || true}
                      onAddNewAccreadition={() => {}}
                      onReloadMembers={() => {}}
                    />
                  ) : (
                    <UserForm
                      user={profileInfo}
                      isLocked={isLocked || true}
                      onAddNewAccreadition={() => {}}
                      onReloadMembers={() => {}}
                    />
                  )}
                </>
              ) : (
                <AnketaForm
                  user={{ ...profileInfo, avatarFile }}
                  projectUuid={currentProject?.uuid}
                  isLocked={isLocked}
                  autoFillTemplate={autoFillTemplate}
                  onAddNewAccreadition={() => {}}
                  onReloadMembers={() => {
                    eventBus.emit(EVENTS.RELOAD_PROFILE);
                  }}
                />
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default AccreaditionSection;
