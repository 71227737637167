import { Button, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import Loader from 'src/components/loader';
import VizitSearchModal from 'src/components/vizit-search-modal';
import { routes } from 'src/constants';
import { useAbility } from 'src/hooks/useAbility';
import UserIcon from 'src/layouts/components/UserIcon';
import UserLayout from 'src/layouts/UserLayout';
import eventBus, { EVENTS } from 'src/utils/eventBus';

import { clearProfileInfo, getProfileInfo, searchUser } from '../../store/actions/application';
import ProfileSection from './ProfileSection';

export const HeaderSection = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'uppercase',
  padding: '16px 0',
  color: theme.palette.text.primary,
}));

export default function UserDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const ability = useAbility();

  const currentProject = useSelector((state) => state.application.currentProject);

  const [isLoading, setIsLoading] = useState(true);
  const [membersVizits, setMembersVizits] = useState([]);
  const [members, setMembers] = useState([]);

  const userUuid = params.id;

  useEffect(() => {
    getProfileInfo(userUuid).then(() => setIsLoading(false));
    return clearProfileInfo;
  }, [userUuid]);

  const fetchMembers = async (userUuid) => {
    if (currentProject) {
      const members = await searchUser({ accreditedBy: userUuid, limit: 1000, projectUuid: currentProject.uuid });
      setMembers(members);
    }
  };

  const handleReloadProfile = () => {
    if (userUuid) {
      setIsLoading(true);
      getProfileInfo(userUuid).then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.RELOAD_PROFILE, handleReloadProfile);
    return () => {
      eventBus.off(EVENTS.RELOAD_PROFILE, handleReloadProfile);
    };
  }, [userUuid]);

  return (
    <UserLayout>
      <Stack gap="24px">
        <Stack alignItems="flex-start" gap="10px">
          <Button
            size="small"
            color="secondary"
            startIcon={<UserIcon icon="mdi:chevron-left" />}
            sx={{
              marginLeft: '-0.5625rem',
              fontSize: '0.75rem',
              lineHeight: '32px',
              '& .MuiButton-startIcon': {
                marginRight: '4px',
              },
            }}
            onClick={() => navigate(routes.users.path)}
          >
            Вернуться к списку
          </Button>
        </Stack>

        {isLoading ? (
          <Loader />
        ) : (
          <ProfileSection
            userUuid={userUuid}
            isLoading={isLoading}
            members={members}
            membersVizits={membersVizits}
            fetchMembers={fetchMembers}
          />
        )}

        <VizitSearchModal />
        <ConfirmModal />
      </Stack>
    </UserLayout>
  );
}
