import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectHookForm } from 'src/@core/components/mui/select';
import { emailTemplateAPIs, generalAPIs } from 'src/services';
import { getLocalizationValue } from 'src/utils/common';

function StepOne({ onSubmit, onCancelDuplicate }) {
  const { register, handleSubmit } = useForm();
  const projects = useSelector((state) => state.application.projects);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="13px" sx={{ maxWidth: '60%', margin: 'auto' }}>
        <Typography variant="body2">
          Выберите проект, чтобы выбрать шаблон электронной почты для дублирования:
        </Typography>
        <SelectHookForm selectProps={{ placeholder: 'Выберите проект', ...register('projectUuid') }}>
          {(projects || []).map((value, i) => (
            <MenuItem key={i} value={value.uuid}>
              <>{getLocalizationValue(value, 'fullName')}</>
            </MenuItem>
          ))}
        </SelectHookForm>
        <Stack direction="row" gap="16px" marginTop="18px" sx={{ '& > *': { flex: '1 1 50%' } }}>
          <Button variant="contained" type="submit" size="large">
            далее
          </Button>
          <Button variant="outlined" color="secondary" size="large" onClick={onCancelDuplicate}>
            назад
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function StepTwo({ roles, onSubmit, defaultValues, onCancelDuplicate }) {
  const { register, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="13px" sx={{ maxWidth: '60%', margin: 'auto' }}>
        <Typography variant="body2">Выберите роль, дублировать поля из ее шаблона электронной почты:</Typography>
        <SelectHookForm selectProps={{ placeholder: 'Выберите проект', ...register('roleUuid') }}>
          {(roles || []).map((value, i) => (
            <MenuItem key={i} value={value.id}>
              <>{value.name?.ru_RU}</>
            </MenuItem>
          ))}
        </SelectHookForm>
        <Stack direction="row" gap="16px" marginTop="18px" sx={{ '& > *': { flex: '1 1 50%' } }}>
          <Button variant="contained" type="submit" size="large">
            далее
          </Button>
          <Button variant="outlined" color="secondary" size="large" onClick={onCancelDuplicate}>
            назад
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function DuplicateForm({ projectUuid, roleUuid, onCancelDuplicate, onRefetchData = () => {} }) {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [roles, setRoles] = useState([]);

  async function handleStepOneSubmit(data) {
    setValues(data);
    fetchData(data.projectUuid);
    setStep(2);
  }

  async function handleStepTwoSubmit(data) {
    try {
      await emailTemplateAPIs.duplicateTemplate({
        projectUuid: projectUuid,
        roleUuid: roleUuid,
        duplicatedProjectUuid: data.projectUuid,
        duplicatedRoleUuid: data.roleUuid,
      });
      onRefetchData();
    } catch (error) {
      console.error(error);
      toast.error(error?.message?.error || 'Шаблон не найден');
    }
  }

  const fetchData = async (projectUuid) => {
    try {
      const resRoles = await generalAPIs.getRoles(projectUuid);
      setRoles(resRoles.message || []);
    } catch (error) {
      console.error(error);
    }
  };

  return step === 1 ? (
    <StepOne onSubmit={handleStepOneSubmit} onCancelDuplicate={onCancelDuplicate} />
  ) : (
    <StepTwo
      roles={roles}
      onSubmit={handleStepTwoSubmit}
      defaultValues={values}
      onCancelDuplicate={onCancelDuplicate}
    />
  );
}

export default DuplicateForm;
