import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { request } from '../../utils/request';
import Loader from '../loader';
import AnketaFields, { INPUT_TYPES } from './AnketaFields';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { keyBy } from 'lodash';
import { createNotification } from '../../utils/notifications';
import classes from './anketa.module.sass';
import AddIcon from '@mui/icons-material/Add';
import { filterUnChangedFields, validatorMessage } from 'src/utils/formValidator';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';

export function AnketaForm({
  user,
  projectUuid,
  isLocked,
  autoFillTemplate,
  ableAddMember,
  quota,
  isAccreditedBy,
  onAddNewAccreadition,
  onReloadMembers,
}) {
  const lang = LOCALIZATION.ru_RU;
  const ability = useAbility();
  const countries = useSelector((state) => state.application.countries);

  const canEditUser = ability.can(ACCESSES.EDIT_USER);
  const userUuid = user?.uuid;
  const roleUuid = user?.projectSettings?.[projectUuid]?.mainRoleId;

  const [isSetup, setIsSetup] = useState(true);

  const fetchAnketaData = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid] = queryKey;
    const [response] = await Promise.all([
      request(`/internal/anketas?projectUuid=${projectUuid}&roleUuid=${roleUuid}`),
    ]);
    return response.message;
  };

  const fetchAnketaUserData = async ({ queryKey }) => {
    const [_, projectUuid, userUuid] = queryKey;
    const [response] = await Promise.all([request(`/internal/projects/${projectUuid}/users/${userUuid}/anketas`)]);
    return response.message;
  };

  const { isLoading: isLoadingData, data } = useQuery(['fetchAnketaData', projectUuid, roleUuid], fetchAnketaData, {
    enabled: !!projectUuid && !!roleUuid,
  });

  const {
    isLoading: isLoadingUserData,
    data: userData,
    refetch: refetchUserData,
  } = useQuery(['fetchAnketaUserData', projectUuid, userUuid, roleUuid], fetchAnketaUserData, {
    enabled: !!projectUuid && !!userUuid,
  });

  const fields = data?.fields || [];

  const validationSchema = fields.reduce((schema, field) => {
    if (field.required) {
      let fieldSchema = {};

      switch (field.AnketaFieldSettings?.typeInput) {
        case 'text':
        case 'long_text':
        case 'url':
        case 'telephone':
        case 'popup_list':
        case 'radio_buttons':
        case 'upload_file':
          fieldSchema = { [field.uuid]: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        case 'email':
          fieldSchema = {
            [field.uuid]: Yup.string()
              .email(validatorMessage.EMAIL_INVALID)
              .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
          };
          break;
        case 'number':
          fieldSchema = { [field.uuid]: Yup.number().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        case 'yes_no':
          fieldSchema = {
            [field.uuid]: Yup.boolean()
              .isTrue(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
              .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
          };
          break;
        case 'checkboxes':
        case 'popup_list_checkboxes':
        case 'list_input':
          fieldSchema = {
            [field.uuid]: Yup.array()
              .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
              .min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
          };
          break;
        case 'data':
        case 'datetime':
          fieldSchema = { [field.uuid]: Yup.date().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) };
          break;
        default:
          break;
      }

      return { ...schema, ...fieldSchema };
    }
    return schema;
  }, {});

  const handleAutoFillValue = (field) => {
    let value;
    Object.keys(autoFillTemplate).forEach((valueKey) => {
      const fieldTemplateUuids = autoFillTemplate[valueKey];
      if (fieldTemplateUuids.includes(field.fieldTemplateUuid)) {
        value = user[valueKey];
      }
    });
    return value;
  };

  const getDefaultValues = () => {
    const fieldValueByUuid = keyBy(userData, 'fieldUuid');
    const defaultValues = fields.reduce((result, field) => {
      const typeInput = field.AnketaFieldSettings?.typeInput;
      const value = userData?.length > 0 ? fieldValueByUuid[field.uuid]?.value : handleAutoFillValue(field);

      return {
        ...result,
        [field.uuid]:
          (typeInput === 'data' || typeInput === 'datetime' ? (value ? new Date(value) : '') : value) ||
          (typeInput === 'yes_no' ? false : INPUT_TYPES[typeInput]?.defaultValue || ''),
      };
    }, {});
    return defaultValues;
  };

  const {
    reset,
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: useMemo(() => {
      return getDefaultValues();
    }, [fields, userData]),
    resolver: yupResolver(Yup.object().shape(validationSchema)),
  });

  useEffect(() => {
    if (!isLoadingUserData && !isLoadingData) {
      const defaultValues = getDefaultValues();
      reset(defaultValues);
      setTimeout(() => {
        setIsSetup(false);
      }, 500);
    }
  }, [isLoadingUserData, isLoadingData, data, userData]);

  const formatValue = (typeInput, value) => {
    let result = value;
    switch (typeInput) {
      case 'number':
        result = parseInt(value);
        break;
      case 'yes_no':
        result = !!value;
        break;
      case 'checkboxes':
      case 'popup_list_checkboxes':
      case 'list_input':
        result = value || [];
        break;
      case 'data':
      case 'datetime':
        result = value ? new Date(value) : null;
        break;
      default:
        result = value || '';
        break;
    }
    return result || '';
  };

  const handleSubmitAnketa = async (values) => {
    try {
      if (userData?.length > 0) {
        // Update
        let newValues = {},
          baseValues = {};
        const userDataByFieldUuid = keyBy(userData, 'fieldUuid');
        data?.fields?.forEach((field) => {
          const fieldUuid = field.uuid;
          const userData = userDataByFieldUuid[fieldUuid];
          baseValues = { ...baseValues, [userData.uuid]: formatValue(userData.typeInput, userData.value) };
          newValues = { ...newValues, [userData.uuid]: formatValue(userData.typeInput, values[userData.fieldUuid]) };
        }, {});
        const body = filterUnChangedFields(baseValues, newValues);
        await request(`/internal/projects/${projectUuid}/users/${userUuid}/anketas`, {
          method: 'PATCH',
          body: Object.keys(body).length > 0 ? newValues : {},
        });
      } else {
        // Create
        const body = {
          roleUuid: roleUuid,
          anketaUuid: data.uuid,
          fields: fields.map((field) => ({
            fieldUuid: field.uuid,
            fieldTemplateUuid: field.fieldTemplateUuid,
            typeInput: field.AnketaFieldSettings?.typeInput,
            value: formatValue(field.AnketaFieldSettings?.typeInput, values[field.uuid]),
          })),
        };
        await request(`/internal/projects/${projectUuid}/users/${userUuid}/anketas`, { method: 'POST', body });
      }
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      refetchUserData();
      if (onReloadMembers) {
        onReloadMembers();
      }
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  return (
    <Stack gap="20px" marginY="20px">
      {roleUuid ? (
        <>
          {isLoadingData || isLoadingUserData || isSetup ? (
            <Loader />
          ) : (
            <>
              {/* <Typography variant="h5">Anketa</Typography> */}
              <form onSubmit={handleSubmit(handleSubmitAnketa)}>
                <Stack gap="32px">
                  <Grid container columnSpacing={8} rowSpacing={2}>
                    {fields.map((field) => (
                      <Grid key={field.uuid} item xs={12} md={6}>
                        <AnketaFields
                          key={field.uuid}
                          field={field}
                          user={user}
                          register={register}
                          control={control}
                          errors={errors}
                          userUuid={userUuid}
                          disabled={!canEditUser || isLocked}
                          countries={countries}
                          autoFillTemplate={autoFillTemplate}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  {canEditUser && (
                    <Stack>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                        variant="contained"
                        size="large"
                        disabled={isSubmitting || isLocked}
                        sx={{
                          width: { xs: '100%', md: '60%' },
                          margin: 'auto',
                        }}
                      >
                        {TEXT[lang].SAVE_CHANGES}
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </form>
            </>
          )}
        </>
      ) : (
        <Alert severity="info" sx={{ alignItems: 'center' }}>
          {MESSAGES[lang].WAIT_UNTIL_ADMIN_SET_ROLE}
        </Alert>
      )}
    </Stack>
  );
}

export default AnketaForm;
