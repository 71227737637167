import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import classes from './filters.module.sass';
import { Button, Grid, Stack } from '@mui/material';
import Input from 'src/components/input';
import Select from 'src/components/select';
import Checkbox from 'src/components/checkbox';
import useResponsive from 'src/hooks/useResponsive';
import { TEXT } from 'src/utils/constant';

export default function UserFilters({ onSubmit }) {
  const isDesktop = useResponsive();
  const location = useLocation();

  const countries = useSelector((state) => state.application.countries);
  const roles = useSelector((state) => state.application.roles);
  const lang = useSelector((state) => state.application.lang);

  const query = qs.parse(location.search);
  const initialValues = {
    ...query,
    mainRoleId: roles.find((c) => c.id === query.mainRoleId)?.id,
  };
  const handleSubmit = (filters) => {
    let baseFilter = { ...filters };
    ['isAccredited', 'isGivenMerch', 'isBadgePrinted'].forEach((key) => {
      if (!baseFilter[key]) {
        delete baseFilter[key];
      }
    });
    return onSubmit(Object.keys(baseFilter).reduce((p, c) => (baseFilter[c] ? { ...p, [c]: baseFilter[c] } : p), {}));
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <Stack gap={6}>
                <Input name="lastName" label="ФАМИЛИЯ" />
                <Input name="firstName" label="ИМЯ" />
                <Input name="middleName" label="ОТЧЕСТВО" />
                <Input name="companyName" label="Название компании" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={6}>
                <Input name="email" label="Email" type="email" />
                <Select
                  name="mainRoleId"
                  label="Роль"
                  options={roles}
                  getOptionLabel={(item) => item.name?.ru_RU}
                  getOptionValue={(item) => item.id}
                  isClearable
                />
                <Input name="phone" label="Телефон" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={6}>
                <Input name="badgeId" label="Номер билета" />
                <Checkbox name="isAccredited" label="Аккредитован" />
                <Checkbox name="isGivenMerch" label="Пользователь уже получил мерч" />
                <Checkbox name="isBadgePrinted" label="Бейдж был напечатан" />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  form.restart();
                  onSubmit({});
                }}
              >
                Очистить фильтры
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button type="submit" variant="contained" className={classes.button}>
                Искать
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
}
