import {
  Alert,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LOCALIZATION } from 'src/constants';
import { setProject } from 'src/store/actions/application';
import { EVENTS, LANGUAGES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { request } from 'src/utils/request';
import { TableHeader } from '.';
import DateRangeSlider, { DATE_FORMAT } from './DateRangeSlider';
import Photos from './Photos';

const TypographyMain = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function DataProfile({ projectUuid, refetchProjectDetail }) {
  const [langue, setLangue] = useState(LANGUAGES[0].lang);

  const currentProject = useSelector((state) => state.application.currentProject);

  const getNameField = (name, langue = LOCALIZATION.ru_RU) => `${name}__${langue}`;

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        [getNameField('fullName')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.fullName,
        [getNameField('shortName')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.shortName,
        [getNameField('comment')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.comment,
        [getNameField('place')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.place,
        [getNameField('themes')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.themes,
        [getNameField('format')]: currentProject?.localization?.[LOCALIZATION.ru_RU]?.format,
        [getNameField('fullName', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.fullName,
        [getNameField('shortName', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.shortName,
        [getNameField('comment', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.comment,
        [getNameField('place', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.place,
        [getNameField('themes', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.themes,
        [getNameField('format', LOCALIZATION.en_US)]: currentProject?.localization?.[LOCALIZATION.en_US]?.format,
        accreditation: currentProject?.modules?.accreditation,
        carsAccreditation: currentProject?.modules?.carsAccreditation,
        tickets: currentProject?.modules?.tickets,
        transfer: currentProject?.modules?.transfer,
        bezviz: currentProject?.modules?.bezviz,
        kultura: currentProject?.modules?.kultura,
        BDK: currentProject?.modules?.BDK,
      }),
      [currentProject]
    ),
  });

  const MODULES = [
    { key: 'accreditation', title: 'Аккредитация', description: '' },
    { key: 'carsAccreditation', title: 'Мобильная аккредитация', description: '' },
    { key: 'tickets', title: 'Билеты', description: '' },
    { key: 'transfer', title: 'Трансфер', description: '' },
    { key: 'bezviz', title: 'безвиз', description: '' },
    { key: 'kultura', title: 'Культура', description: '' },
    { key: 'BDK', title: 'БДК', description: '' },
  ];

  const submitUpdateProject = async (data) => {
    let body = { localization: {}, modules: {} };
    Object.keys(data).map((key) => {
      const splittedKey = key.split('__');
      if (splittedKey.length > 1) {
        const localize = splittedKey[1];
        const originKey = splittedKey[0];
        body = {
          ...body,
          localization: {
            ...body.localization,
            [localize]: { ...(body.localization[localize] || {}), [originKey]: data[key] },
          },
        };
      } else if (MODULES.find((mod) => mod.key === key)) {
        body = {
          ...body,
          modules: { ...body.modules, [key]: data[key] },
        };
      } else {
        body = { ...body, [key]: data[key] };
      }
    });

    const BODY_DATE_FORMAT = 'yyyy-MM-dd';
    body = {
      ...body,
      installationDatePlan: format(dateValues[0], BODY_DATE_FORMAT),
      deinstallationDatePlan: format(dateValues[1], BODY_DATE_FORMAT),
      dateStartPlan: format(startDateRange.getTime(), BODY_DATE_FORMAT),
      dateFinishPlan: format(endDateRange.getTime(), BODY_DATE_FORMAT),
    };

    try {
      const res = await request(`/internal/projects/${projectUuid}`, { method: 'PATCH', body });
      setProject(res.message);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const handleUpdateProjectProfile = (data) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_UPDATE_PROJECT_MODAL, {
      onOk: async () => await submitUpdateProject(data),
    });
  };

  const dateStartPlan = moment.unix(currentProject?.dateStartPlan).utc();
  const dateFinishPlan = moment.unix(currentProject?.dateFinishPlan).utc();
  const installationDatePlan = moment.unix(currentProject?.installationDatePlan);
  const deinstallationDatePlan = moment.unix(currentProject?.deinstallationDatePlan);

  const [startDateRange, setStartDateRange] = useState(dateStartPlan.toDate());
  const [endDateRange, setEndDateRange] = useState(dateFinishPlan.toDate());
  const [dateValues, setDateValues] = useState([
    installationDatePlan.toDate().getTime(),
    deinstallationDatePlan.toDate().getTime(),
  ]);

  const handleOnChangeRange = (dates) => {
    const [start, end] = dates;
    setStartDateRange(start);
    setEndDateRange(end);
  };

  const CustomInput = forwardRef((props, ref) => {
    const startDate = moment(props.start).utc().format('DD/MM/yyyy');
    const endDate = props.end !== null ? `- ${moment(props.end).format('DD/MM/yyyy')}` : null;

    const value = `${startDate}${endDate !== null ? endDate : ''}`;

    return <TextField fullWidth inputRef={ref} label={props.label || ''} {...props} value={value} />;
  });

  const handleReset = () => {
    setStartDateRange(dateStartPlan.toDate());
    setEndDateRange(dateFinishPlan.toDate());
    setDateValues([installationDatePlan.toDate().getTime(), deinstallationDatePlan.toDate().getTime()]);
  };

  useEffect(() => {
    handleReset();
  }, [currentProject]);

  return (
    <>
      <TableHeader>ДАТЫ ПРОЕКТА</TableHeader>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Stack>
            <DatePicker
              selectsRange
              monthsShown={2}
              endDate={endDateRange}
              selected={startDateRange}
              startDate={startDateRange}
              shouldCloseOnSelect={false}
              onChange={handleOnChangeRange}
              popperPlacement="bottom-start"
              customInput={<CustomInput label="План" end={endDateRange} start={startDateRange} />}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack>
            <Stack direction="row" gap="20px">
              {startDateRange && (
                <TypographyMain variant="body2">{format(startDateRange.getTime(), DATE_FORMAT)}</TypographyMain>
              )}

              <DateRangeSlider
                startDate={startDateRange}
                endDate={endDateRange}
                value={dateValues}
                onChange={(newValue) => setDateValues(newValue)}
              />
              {endDateRange && (
                <TypographyMain variant="body2">{format(endDateRange.getTime(), DATE_FORMAT)}</TypographyMain>
              )}
            </Stack>
            <TypographyMain variant="caption" align="center" sx={{ textTransform: 'uppercase' }}>
              монтаж
            </TypographyMain>
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      <Grid container rowSpacing={5} columnSpacing={12.5}>
        <Grid item xs={12} md={4} lg={3}>
          <Stack gap="18px">
            {LANGUAGES.map((lang) => (
              <Button
                key={lang.lang}
                variant={langue === lang.lang ? 'contained' : 'text'}
                color={langue === lang.lang ? 'primary' : 'secondary'}
                onClick={() => {
                  setLangue(lang.lang);
                }}
                sx={{ whiteSpace: 'nowrap' }}
                startIcon={<img src={lang.flag} alt="flag" style={{ width: '16px', height: '16px' }} />}
              >
                {lang.lang === LOCALIZATION.ru_RU ? 'РУССКИЙ ЯЗЫК' : 'АНГЛИЙСКИЙ ЯЗЫК'}
              </Button>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <form onSubmit={handleSubmit(handleUpdateProjectProfile)}>
            <TableHeader>ХАРАКТЕРИСТИКИ</TableHeader>
            <Stack marginTop="16px" gap="30px" sx={{ display: langue === LOCALIZATION.en_US ? 'flex' : 'none' }}>
              <TextField
                label="ПОЛНОЕ НАЗВАНИЕ"
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('fullName', LOCALIZATION.en_US))}
              />
              <TextField
                label="КОРОТКОЕ НАЗВАНИЕ"
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('shortName', LOCALIZATION.en_US))}
              />
              <TextField
                label="ОПИСАНИЕ"
                multiline
                minRows={3}
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('comment', LOCALIZATION.en_US))}
              />
              <Divider />
            </Stack>

            <Stack marginTop="16px" gap="30px" sx={{ display: langue === LOCALIZATION.ru_RU ? 'flex' : 'none' }}>
              <TextField
                label="ПОЛНОЕ НАЗВАНИЕ"
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('fullName'))}
              />
              <TextField
                label="КОРОТКОЕ НАЗВАНИЕ"
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('shortName'))}
              />
              <TextField
                label="ОПИСАНИЕ"
                multiline
                minRows={3}
                InputLabelProps={{ shrink: true }}
                {...register(getNameField('comment'))}
              />
              <Divider />
            </Stack>

            <Stack gap="18px" marginTop="18px">
              <TableHeader>функции</TableHeader>
              {MODULES.map((pModule) => (
                <>
                  <FormControlLabel
                    key={pModule.key}
                    control={
                      <Controller
                        name={pModule.key}
                        control={control}
                        render={({ field }) => (
                          <Switch onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />
                        )}
                      />
                    }
                    label={
                      <Stack>
                        <Typography variant="body1">{pModule.title}</Typography>
                        {pModule.description && <Typography variant="caption">{pModule.description}</Typography>}
                      </Stack>
                    }
                  />
                </>
              ))}
            </Stack>

            <Grid marginY="30px" container rowSpacing={5} columnSpacing={8}>
              <Grid item xs={12} sx={{ display: langue === LOCALIZATION.en_US ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Введите название темы"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('themes', LOCALIZATION.en_US))}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: langue === LOCALIZATION.en_US ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Формат"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('format', LOCALIZATION.en_US))}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: langue === LOCALIZATION.en_US ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Место"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('place', LOCALIZATION.en_US))}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: langue === LOCALIZATION.ru_RU ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Введите название темы"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('themes'))}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: langue === LOCALIZATION.ru_RU ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Формат"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('format'))}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: langue === LOCALIZATION.ru_RU ? 'flex' : 'none' }}>
                <TextField
                  fullWidth
                  label="Место"
                  InputLabelProps={{ shrink: true }}
                  {...register(getNameField('place'))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth label="САЙТ" InputLabelProps={{ shrink: true }} {...register('web')} />
              </Grid>
            </Grid>

            <Stack gap="30px" marginBottom="30px">
              <Divider />
              <Stack gap="18px">
                <Stack direction="row" gap="24px" sx={{ '& button': { flex: '1 1 50%' } }}>
                  <Button variant="contained" type="submit">
                    сохранить
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleReset();
                      reset();
                    }}
                  >
                    отменить
                  </Button>
                </Stack>

                <Alert severity="error">После сохранения изменений синхронизация будет отключена.</Alert>
              </Stack>
            </Stack>

            <Divider />

            <Photos projectUuid={projectUuid} refetchProjectDetail={refetchProjectDetail} />
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default DataProfile;
