import { useNavigate } from 'react-router-dom';
import ButtonLoading from 'src/components/button-loading';
import { routes } from 'src/constants';
import { useLanguage } from 'src/hooks';
import { isSafari } from 'src/utils/common';
import { createErrorNotification } from 'src/utils/notifications';

function DownloadTicketButton({ userUuid, projectUuid, disabled }) {
  const navigate = useNavigate();
  const { localizeText } = useLanguage();

  const handleDownloadTicket = () => {
    if (!userUuid) return;
    if (!projectUuid) {
      return createErrorNotification('Требуется текущий проект');
    }
    const url = routes.myTicket.path.replace(':userUuid', userUuid).replace(':projectUuid', projectUuid);
    if (isSafari) {
      navigate(url);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <ButtonLoading variant="outlined" disabled={disabled} onClick={handleDownloadTicket}>
      {localizeText.DOWNLOAD_TICKET}
    </ButtonLoading>
  );
}

export default DownloadTicketButton;
