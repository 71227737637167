import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { emailTemplateAPIs } from 'src/services';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import classes from './email.module.sass';
import { LANGUAGES } from 'src/utils/constant';
import Input from 'src/components/input';
import Select from 'src/components/select';
import Checkbox from 'src/components/checkbox';
import { Form } from 'react-final-form';

function TemplateForm({
  template,
  projectUuid,
  roleUuid,
  isRefetching,
  onRefetchData = () => {},
  onCancelAddNew = () => {},
}) {
  const lang = LOCALIZATION.ru_RU;
  const [editorState, setEditorState] = useState();
  const [engEditorState, setEngEditorState] = useState();

  const handleSubmitTemplate = async (values) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlString = draftToHtml(rawContentState);
    const rawEngContentState = convertToRaw(engEditorState.getCurrentContent());
    const engHtmlString = draftToHtml(rawEngContentState);

    try {
      if (template) {
        await emailTemplateAPIs.updateTemplate(template.uuid, {
          body: htmlString,
          englishBody: engHtmlString,
          ...values,
        });
        createNotification(MESSAGES[lang].UPDATE_SUCCESSFULLY, 'success');
      } else {
        await emailTemplateAPIs.createTemplate({
          projectUuid,
          roleUuid,
          body: htmlString,
          englishBody: engHtmlString,
          ...values,
        });
        createNotification(MESSAGES[lang].CREATE_SUCCESSFULLY, 'success');
        onCancelAddNew();
      }
    } catch (error) {
      console.error(error);
      createErrorNotification(error?.message?.error || MESSAGES[lang].ERROR);
    } finally {
      onRefetchData();
    }
  };

  const formatHTMLStringToDraft = (htmlString) => {
    const blocksFromHtml = htmlToDraft(htmlString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const handleCancel = () => {
    if (template) {
      setEditorState(formatHTMLStringToDraft(template.body || ''));
      setEngEditorState(formatHTMLStringToDraft(template.englishBody || ''));
    } else {
      onCancelAddNew();
    }
  };

  useEffect(() => {
    if (template) {
      setEditorState(formatHTMLStringToDraft(template.body || ''));
      setEngEditorState(formatHTMLStringToDraft(template.englishBody || ''));
    }
  }, [template]);

  const [langue, setLangue] = useState(LANGUAGES[0].lang);

  const FOR_EVENTS_OPTIONS = [
    { label: 'Регистрация на мероприятии', value: 'register_event' },
    { label: 'Прохождения аккредитации', value: 'accreditation_event' },
    { label: 'отказ в аккредитации', value: 'refuse_accreditation_event' },
  ];

  return (
    <Form
      initialValues={{
        forEvent: FOR_EVENTS_OPTIONS[0].value,
        subject: '',
        sendAttachment: false,
      }}
      onSubmit={handleSubmitTemplate}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Stack sx={{ width: '70%', margin: 'auto' }} gap="24px">
            <Stack gap="16px">
              <Select
                name="forEvent"
                label="Для события"
                options={FOR_EVENTS_OPTIONS}
                getOptionLabel={(item) => item.label}
                getOptionValue={(item) => item.value}
              />
              <Input name="subject" label="Укажите тему письма" />
              <Checkbox name="sendAttachment" label="Отправлять вложения" />
            </Stack>

            <Stack direction="row" gap="16px">
              {LANGUAGES.map((lang) => (
                <Button
                  key={lang.lang}
                  variant={langue === lang.lang ? 'contained' : 'text'}
                  color={langue === lang.lang ? 'primary' : 'secondary'}
                  onClick={() => {
                    setLangue(lang.lang);
                  }}
                  sx={{ whiteSpace: 'nowrap', paddingX: '16px' }}
                  startIcon={<img src={lang.flag} alt="flag" style={{ width: '16px', height: '16px' }} />}
                >
                  {lang.lang === LOCALIZATION.ru_RU ? 'РУССКИЙ ЯЗЫК' : 'АНГЛИЙСКИЙ ЯЗЫК'}
                </Button>
              ))}
            </Stack>

            <Stack>
              <ul>
                <li>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 700, fontSize: '16px' }}
                  >{`Заменить логин шаблоном {%login}`}</Typography>
                </li>
                <li>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 700, fontSize: '16px' }}
                  >{`Заменить пароль шаблоном {%password}`}</Typography>
                </li>
              </ul>
              <Editor
                editorState={langue === LOCALIZATION.ru_RU ? editorState : engEditorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName={classes.editorClassName}
                onEditorStateChange={(newState) => {
                  langue === LOCALIZATION.ru_RU ? setEditorState(newState) : setEngEditorState(newState);
                }}
                toolbar={{ image: { uploadEnabled: true } }}
              />
            </Stack>
            <Box>
              <Grid container spacing={7.5}>
                <Grid item xs={12} md={6}>
                  <Button fullWidth variant="outlined" size="large" onClick={handleCancel}>
                    Отмена
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={isRefetching || submitting}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </form>
      )}
    </Form>
  );
}

export default TemplateForm;
