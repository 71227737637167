import { Autocomplete, Box, createFilterOptions, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';

const filter = createFilterOptions();

function AnketaOptionField({ label, options = [], setOptions, disabled, error }) {
  return (
    <Autocomplete
      multiple={true}
      value={options || []}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setOptions([...options, { inputValue: newValue, title: `"${newValue}"` }]);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setOptions([...options, { inputValue: newValue.inputValue, title: `"${newValue.inputValue}"` }]);
        } else {
          setOptions(
            newValue.map((value) => (typeof value === 'string' ? { inputValue: value, title: `"${value}"` } : value))
          );
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.inputValue);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `"${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ ...params?.InputLabelProps, shrink: true }}
          InputProps={{
            ...params?.InputProps,
            sx: { minHeight: '128px', alignItems: 'flex-start' },
          }}
          label={label}
          error={!!error}
          helperText={error}
        />
      )}
      disabled={disabled}
    />
  );
}

export default AnketaOptionField;
