import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { renderMultiSelect, SelectHookForm } from 'src/@core/components/mui/select';
import { request } from 'src/utils/request';
import classes from './anketa.module.sass';
import * as yup from 'yup';
import AnketaOptionField from './AnketaOptionField';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { validatorMessage } from 'src/utils/formValidator';
import { LanguageMuiSelect } from 'src/components/language-select';
import { LOCALIZATION } from 'src/utils/constant';
import { autoFillObjectValues, getLocalizationAnketaFields } from 'src/utils/common';

export const FIELD_TYPES = [
  { value: 'text', label: 'текстовый ввод' },
  { value: 'long_text', label: 'многострочный текст' },
  { value: 'email', label: 'Email' },
  { value: 'telephone', label: 'номер телефона' },
  { value: 'number', label: 'число' },
  { value: 'url', label: 'URL' },
  { value: 'yes_no', label: 'выбор да/нет' },
  { value: 'checkboxes', label: 'множественный выбор (галки)' },
  { value: 'popup_list', label: 'выпадающий список' },
  { value: 'popup_list_checkboxes', label: 'множественный выбор (выпадающий список)' },
  { value: 'radio_buttons', label: 'радио-кнопки' },
  { value: 'upload_file', label: 'загрузка файлов' },
  { value: 'data', label: 'дата' },
  { value: 'datetime', label: 'дата и время' },
  { value: 'list_input', label: 'список+ввод' },
];

const schema = yup.object().shape({
  name: yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export const getLocalizationAnketaFieldsData = (currentLang, cacheValues, options, formValues) => {
  const cacheValuesRu = getLocalizationAnketaFields(cacheValues[LOCALIZATION.ru_RU]);
  const cacheValuesEn = getLocalizationAnketaFields(cacheValues[LOCALIZATION.en_US]);

  const optionsRu = currentLang === LOCALIZATION.ru_RU ? options : cacheValuesRu?.options;
  const optionsEn = currentLang === LOCALIZATION.en_US ? options : cacheValuesEn?.options;
  if (optionsRu?.length !== optionsEn?.length) {
    return { optionsError: 'Параметры английская и русская версии должны быть одинаковой длины' };
  }

  const localization = {
    [LOCALIZATION.en_US]: getLocalizationAnketaFields(
      Object.assign(
        currentLang === LOCALIZATION.en_US
          ? autoFillObjectValues(formValues, cacheValuesRu)
          : autoFillObjectValues(cacheValuesEn, formValues),
        {
          options: currentLang === LOCALIZATION.en_US ? options : cacheValuesEn?.options,
        }
      )
    ),
    [LOCALIZATION.ru_RU]: getLocalizationAnketaFields(
      Object.assign(
        currentLang === LOCALIZATION.ru_RU
          ? autoFillObjectValues(formValues, cacheValuesEn)
          : autoFillObjectValues(cacheValuesRu, formValues),
        {
          options: currentLang === LOCALIZATION.ru_RU ? options : cacheValuesRu?.options,
        }
      )
    ),
  };
  const newInfoValues =
    currentLang === LOCALIZATION.ru_RU
      ? formValues
      : {
          ...formValues,
          ...localization[LOCALIZATION.ru_RU],
        };
  return { newInfoValues, localization };
};

function AnketaAddField({ projectUuid, roleUuid, anketaUuid, anketaFields, setShowAddNewField, refetchAnketaData }) {
  const {
    reset,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      typeInput: FIELD_TYPES[0].value,
      badgeName: '',
      defaultText: '',
      subText: '',
      hintText: '',
      length: 256,
    },
    resolver: yupResolver(schema),
  });

  const [lang, setLang] = useState(LOCALIZATION.ru_RU);
  const [cacheValues, setCacheValues] = useState({});
  const [options, setOptions] = useState([]);
  const [optionsError, setOptionsError] = useState('');

  useEffect(() => {
    setOptionsError('');
  }, [options]);

  const handleAddNewField = async (data) => {
    try {
      const numOfFields = anketaFields?.length || 0;
      const position = numOfFields + 1;
      const length = parseInt(data?.length);

      const { optionsError, newInfoValues, localization } = getLocalizationAnketaFieldsData(
        lang,
        cacheValues,
        options,
        data
      );
      if (optionsError) return setOptionsError(optionsError);

      if (numOfFields === 0) {
        // Add anketa
        await request('/internal/anketas/add', {
          method: 'POST',
          body: {
            projectUuid: projectUuid,
            roleUuid: roleUuid,
            fields: [
              {
                ...newInfoValues,
                localization,
                fieldTemplateUuid: '',
                position: 1,
                options: localization[LOCALIZATION.ru_RU].options,
                length,
              },
            ],
          },
        });
      } else {
        // Add anketa field
        await request(`/internal/anketas/${anketaUuid}`, {
          method: 'POST',
          body: {
            ...newInfoValues,
            localization,
            fieldTemplateUuid: '',
            position: position,
            options: localization[LOCALIZATION.ru_RU].options,
            length,
          },
        });
      }
      refetchAnketaData();
      setShowAddNewField(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message?.error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  const handleSetLang = (lang) => {
    const currentValues = Object.assign(getValues() || {}, { options });
    const currentCaches = { ...cacheValues };
    setCacheValues((old) => ({
      ...old,
      [lang === LOCALIZATION.ru_RU ? LOCALIZATION.en_US : LOCALIZATION.ru_RU]:
        getLocalizationAnketaFields(currentValues),
    }));
    const newValues = {
      ...{ typeInput: currentValues.typeInput, length: currentValues.length },
      ...getLocalizationAnketaFields(currentCaches[lang] || {}),
    };
    Object.keys(newValues).forEach((key) => setValue(key, newValues[key]));
    reset(newValues);
    setOptions(newValues.options || []);
    setLang(lang);
  };

  return (
    <form onSubmit={handleSubmit(handleAddNewField)}>
      <Card>
        <Stack>
          <Stack
            direction="row"
            gap="16px"
            justifyContent="space-between"
            paddingX="20px"
            paddingY="6px"
            bgcolor="#EDEDF3"
          >
            <Stack direction="row" flexWrap="wrap" gap="10px">
              <Typography
                variant="body2"
                lineHeight="24px"
                fontWeight={500}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                Поле шаблона анкеты:
              </Typography>
            </Stack>

            <Stack direction="row" gap="10px">
              <IconButton sx={{ padding: 0, borderRadius: 0 }} type="html">
                <Check />
              </IconButton>
              <IconButton sx={{ padding: 0, borderRadius: 0 }} onClick={() => setShowAddNewField(false)}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <CardContent>
            <Stack>
              <Grid container rowSpacing={4} columnSpacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="заголовок"
                    size="small"
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LanguageMuiSelect lang={lang} setLang={handleSetLang} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    component="p"
                    variant="caption"
                    lineHeight="18px"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >{`Для вставки ссылок используйте формат: {текст_ссылки}`}</Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                  <Stack>
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>
                      Настройки поля:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap="16px">
                    <SelectHookForm
                      label="специальный тип поля"
                      selectProps={{ ...register('typeInput'), defaultValue: FIELD_TYPES[0].value }}
                    >
                      {FIELD_TYPES.map((option, i) => (
                        <MenuItem key={`typeInput-${i}`} value={option.value}>
                          <>{option.label}</>
                        </MenuItem>
                      ))}
                    </SelectHookForm>
                    <AnketaOptionField
                      label="варианты выбора"
                      options={options.map((option) => ({ inputValue: option, title: option }))}
                      setOptions={(options) => setOptions(options.map((option) => option.inputValue))}
                      error={optionsError}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="имя переменной бейджа"
                      {...register('badgeName')}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap="16px">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="по-умолчанию"
                      {...register('defaultText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="текст заглушки"
                      {...register('subText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="текст подсказки"
                      {...register('hintText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      fullWidth
                      label="длина"
                      {...register('length')}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox {...register('required')} color="primary" />}
                    label="Обязательно"
                  />
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Stack>
      </Card>
    </form>
  );
}

export default AnketaAddField;
