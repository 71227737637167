import { AttachFile, Delete } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'src/@core/components/mui/select';
import DocumentFile from 'src/components/document-file';
import Loader from 'src/components/loader';
import { useFetchRoles } from 'src/hooks';
import { documentAPIs } from 'src/services';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createNotification } from 'src/utils/notifications';

function UploadInfoFilesSettings({ projectUuid }) {
  const [currentRole, setCurrentRole] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchDocument = async ({ queryKey }) => {
    const [, projectUuid, roleUuid] = queryKey;
    const res = await documentAPIs.getDocuments({ type: 'document_information', projectUuid, roleUuid });
    return res.message;
  };

  const { isLoading: isLoadingRoles, data: roles } = useFetchRoles({ projectUuid });
  const { data: documents, refetch: refetchDocuments } = useQuery(
    ['fetchDocument', projectUuid, currentRole],
    fetchDocument,
    {
      enabled: !!projectUuid && !!currentRole,
    }
  );

  const handleUpload = async (e) => {
    try {
      setIsSubmitting(true);
      let body = new FormData();
      body.append('fileDocument', currentFile);
      body.append(
        'document',
        JSON.stringify({
          typeDoc: 'document_information',
          projectUuid: projectUuid,
          roleUuid: currentRole,
          description: description,
          fileName: fileName || currentFile.name,
          contentType: currentFile.type,
        })
      );
      await documentAPIs.uploadDocument(body);
      refetchDocuments();
      createNotification('Загрузка успешна', 'success');
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    } finally {
      setIsSubmitting(false);
      setCurrentFile(null);
      setFileName('');
      setDescription('');
    }
  };

  const handleDeleteDocument = async (document) => {
    try {
      await documentAPIs.deleteDocumentFile(document.uuid);
      createNotification(MESSAGES[LOCALIZATION.ru_RU].DELETE_SUCCESSFULLY, 'success');
      refetchDocuments();
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  const currentFileURL = useMemo(() => {
    if (!currentFile) return '';
    const urlCreator = window.URL || window.webkitURL;
    const fileURL = urlCreator.createObjectURL(currentFile);
    return fileURL;
  }, [currentFile]);

  if (isLoadingRoles) return <Loader />;
  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
            Для роли:
          </Typography>
          <Select
            fullWidth={true}
            label="Выберите роль"
            options={roles}
            value={currentRole}
            getOptionLabel={(item) => item.name?.ru_RU}
            getOptionValue={(item) => item.id}
            onChange={(value) => setCurrentRole(value)}
          />
        </Stack>
      </CardContent>

      <Card>
        <CardContent>
          {!currentRole ? (
            <p style={{ textAlign: 'center' }}>Выберите роль для загрузки файла</p>
          ) : (
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Stack gap="16px">
                  <Stack direction="row" gap="8px" alignItems="center">
                    <Button
                      startIcon={<AttachFile />}
                      variant="contained"
                      component="label"
                      sx={{ width: 'fit-content' }}
                    >
                      Загрузить
                      <input
                        hidden
                        type="file"
                        onChange={(e) => {
                          const newFile = e.target.files[0];
                          const fileNameArr = newFile.name.split('.');
                          if (!fileName) {
                            setFileName(fileNameArr.slice(0, fileNameArr.length - 1).join(''));
                          }
                          setCurrentFile(newFile);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                    </Button>
                    {currentFile && (
                      <>
                        <Link href={currentFileURL} target="_blank" sx={{ cursor: 'pointer' }}>
                          {currentFile.name}
                        </Link>
                        <IconButton onClick={() => setCurrentFile(null)}>
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                  <TextField
                    label="Имя файла"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />
                  <TextField
                    label="Описание"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Button variant="contained" disabled={!currentFile || isSubmitting} onClick={handleUpload}>
                    Сохранить
                  </Button>
                </Stack>

                {documents?.length > 0 && (
                  <>
                    <Divider sx={{ width: '100%', marginY: '24px' }} />
                    <Stack gap="24px">
                      <Stack direction="row" alignItems="center" gap="8px">
                        <Typography variant="body1" fontWeight={700}>
                          Загруженные документы:
                        </Typography>
                      </Stack>
                      {documents?.map((document, i) => (
                        <DocumentFile key={i} document={document} onDeleteDocument={handleDeleteDocument} />
                      ))}
                    </Stack>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Card>
  );
}

export default UploadInfoFilesSettings;
