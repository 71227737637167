import { Card, CardContent, Chip, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { userAPIs } from 'src/services';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { createNotification } from 'src/utils/notifications';
import Loader from 'src/components/loader';
import { HOST_URL } from 'src/utils/constant';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { useProfile } from 'src/context/ProfileContext';

function ProfileInvitations({ userUuid, projectUuid }) {
  const roles = useSelector((state) => state.application.roles);

  const { setProfile } = useProfile();

  const roleByUuid = roles.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  const fetchUserInvitations = async ({ queryKey }) => {
    const [_, userUuid, projectUuid] = queryKey;
    const response = await userAPIs.getInvitations(userUuid, projectUuid);
    setProfile?.((prev) => ({ ...prev, invitations: response.message }));
    return response.message;
  };

  const { isLoading, data, refetch } = useQuery(['fetchInvitations', userUuid, projectUuid], fetchUserInvitations, {
    enabled: !!userUuid && !!projectUuid,
  });

  useEffect(() => {
    eventBus.on(EVENTS.RELOAD_INVITATION_LINKS, refetch);
    return () => {
      eventBus.off(EVENTS.RELOAD_INVITATION_LINKS, refetch);
    };
  }, [userUuid, projectUuid]);

  if (!isLoading && Object.keys(data || {}).length === 0) return null;
  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : (
          <Stack>
            <Typography variant="h6" marginBottom="8px">
              Приглашения
            </Typography>
            <Stack gap="4px">
              {Object.keys(data || []).map((roleUuid) => {
                return (
                  <React.Fragment key={roleUuid}>
                    <Typography variant="body1">- {roleByUuid[roleUuid]?.name?.ru_RU}:</Typography>
                    {data[roleUuid].map((invitation) => {
                      const link = `${HOST_URL}/register?invitation_id=${invitation.uuid}`;
                      return (
                        <Stack key={invitation.uuid} direction="row" gap="16px" alignItems="center">
                          <Link href={link} target="_blank" variant="body2">
                            {link}
                          </Link>
                          <Tooltip title="Копировать">
                            <IconButton
                              onClick={() => {
                                try {
                                  navigator.clipboard.writeText(link);
                                  createNotification('Скопировано в буфер обмена');
                                } catch (error) {
                                  console.error(error);
                                  createNotification('Что-то пошло не так', 'error');
                                }
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                          {invitation.leftCount > 0 && <Chip color="success" label="Активно" />}
                          {!invitation.leftCount && <Chip color="error" label="Неактивно" />}
                        </Stack>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

export default ProfileInvitations;
