import { request } from '../utils/request';

export const generalAPIs = {
  startProjectsLoader: () =>
    request(`/internal/debug/run-project-loader`, {
      method: 'POST',
    }),
  syncProject: (projectUuid, syncAll) =>
    request(`/internal/debug/sync-project/${projectUuid}${syncAll ? `?syncAll=${syncAll}` : ''}`, {
      method: 'POST',
    }),
  getRoles: (projectUuid) => request(`/roles?projectUuid=${projectUuid}`, { method: 'GET' }),
  getOrders: (query) => request(`/internal/orders${query ? `?${query}` : ''}`, { method: 'GET' }),
  syncOrders: () => request(`/internal/debug/start-check-order-worker`),
  getAutoFillFields: () => request(`/auto-fill-templates`, { method: 'GET' }),
};
