import { Button, Grid, Stack } from '@mui/material';
import qs from 'query-string';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import Input from 'src/components/input';
import Select from 'src/components/select';
import useResponsive from 'src/hooks/useResponsive';
import { LOCALIZATION, STATUS_PAYMENT } from 'src/utils/constant';
import classes from './filters.module.sass';

export default function OrderFilters({ onSubmit }) {
  const isDesktop = useResponsive();
  const location = useLocation();

  const PAYMENT_METHOD_OPTIONS = [
    {
      id: 'order_by_card',
      label: 'Оплата банковской картой',
    },
    {
      id: 'order_by_company',
      label: 'Оплата по счёту',
    },
  ];

  const STATUS_OPTION = Object.keys(STATUS_PAYMENT).map((key) => ({
    id: key,
    label: STATUS_PAYMENT[key].label[LOCALIZATION.ru_RU],
  }));

  const query = qs.parse(location.search);
  const initialValues = {
    ...query,
  };
  const handleSubmit = (filters) => {
    let baseFilter = { ...filters };
    return onSubmit({
      ...baseFilter,
    });
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Stack gap={isDesktop ? '36px' : '24px'}>
            <Grid container rowSpacing={6} columnSpacing={12}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="companyName" label="Название компании" />
                  <Select
                    name="status"
                    label="Статус"
                    options={STATUS_OPTION}
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.id}
                    isClearable
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="thezisId" label="Thezis ID" />
                  <Select
                    name="paymentMethod"
                    label="Способ оплаты"
                    options={PAYMENT_METHOD_OPTIONS}
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.id}
                    isClearable
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="packageName" label="Имя пакета" />
                </Stack>
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={12}>
              <Grid item xs={12} md={12} lg={4}></Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => {
                    form.restart();
                    onSubmit({});
                  }}
                >
                  Очистить фильтры
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button type="submit" variant="contained" className={classes.button}>
                  Искать
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      )}
    </Form>
  );
}
