import { AccountCircle, Check, Clear, Download, Groups, ImageSearch, LocalPrintshop, Store } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Avatar, Box, Button, CircularProgress, Collapse, Fab, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionsButton from 'src/components/atoms/button/ActionsButton';
import ButtonLoading from 'src/components/button-loading';
import { MUITable } from 'src/components/mui-table';
import UserStatus from 'src/components/status/UserStatus';
import { accreditationStatuses, routes } from 'src/constants';
import { useLanguage } from 'src/hooks';
import { useAbility } from 'src/hooks/useAbility';
import { projectAPIs } from 'src/services';
import { editUser, updateUserByProjectUuid } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { isSafari, preventSpreadEvent } from 'src/utils/common';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import UserFilters from './UserFilter';

function UserList({ projectUuid, loading, isBezviz, statuses, queryParams = {}, setQueryParams, onReloadTable }) {
  const navigate = useNavigate();
  const ability = useAbility();
  const { lang, localizeMessage, localizeText } = useLanguage();

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);
  const roles = useSelector((state) => state.application.roles);

  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);

  const [showFilter, setShowFilter] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tab = queryParams.tab || statuses.processing.name;
  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canPrintBadge = ability.can(ACCESSES.PRINT_BADGE);

  useEffect(() => {
    if (
      Object.keys(queryParams).filter(
        (param) => !['tab', 'page', 'limit', 'sortOrder', 'sortBy'].includes(param) && queryParams[param]
      ).length > 0
    ) {
      setShowFilter(true);
    }
  }, [queryParams]);

  // const { reset, register, getValues, handleSubmit } = useForm({
  //   defaultValues: { query: queryParams?.query || '' },
  // });

  // const handleSearch = ({ fullName }) => {
  //   if (fullName && fullName.trim()) {
  //     setQueryParams({ ...queryParams, fullName: fullName });
  //   } else {
  //     let _queryParams = queryParams;
  //     delete _queryParams.fullName;
  //     setQueryParams(_queryParams);
  //   }
  // };

  const handleFilter = (filters) => {
    setQueryParams({ ...filters, tab: queryParams.tab });
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleUpdateUser = async ({ userUuid, isAccredited }) => {
    if (userUuid) {
      await updateUserByProjectUuid(userUuid, { isAccredited });
      onReloadTable();
    }
  };

  const handleDownloadTicket = (userUuid) => {
    if (!userUuid) return;
    if (!currentProject) {
      return createErrorNotification('Требуется текущий проект');
    }
    const url = `/my-ticket/${userUuid}/${currentProject?.uuid}`;
    if (isSafari) {
      navigate(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleDownloadBadge = async (userUuid) => {
    try {
      const response = await projectAPIs.generateBadge(projectUuid, userUuid);
      const blobURL = URL.createObjectURL(response);
      window.open(blobURL, '_blank').print();
      // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
      //   images: [{ src: blobURL, blob: response }],
      //   userUuid: userUuid,
      //   projectUuid: projectUuid,
      // });
      createNotification('Загрузка прошла успешно', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const headCells = [
    {
      id: 'icons',
      numeric: false,
      disablePadding: true,
      label: '',
      renderRow: (row) => {
        const hasBadge = row.badgeUuid != '';
        const isMerch = row.projectSettings?.[currentProject?.uuid]?.isGivenMerch;
        const isSelfRegister = row.accreditedBy == '';
        const isBadgePrinted = row.projectSettings?.[currentProject?.uuid]?.isBadgePrinted;
        return (
          <Stack direction="row" gap="4px">
            {isMerch && (
              <Tooltip title="Пользователь уже получил мерч">
                <Store color="warning" />
              </Tooltip>
            )}
            {isSelfRegister && (
              <Tooltip title="Пользователь создал анкета самостоятельно">
                <AccountCircle color="black" />
              </Tooltip>
            )}
            {hasBadge && (
              <Tooltip title="Бейдж был создан">
                <ImageSearch color="success" />
              </Tooltip>
            )}
            {isBadgePrinted && (
              <Tooltip title="Бейдж был напечатан">
                <LocalPrintshop color="success" />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      id: 'last_name',
      numeric: false,
      disablePadding: false,
      label: 'фамилия',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.lastName || ''}</Typography>,
    },
    {
      id: 'first_name',
      numeric: false,
      disablePadding: false,
      label: 'имя',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.firstName || ''}</Typography>,
    },
    {
      id: 'middle_name',
      numeric: false,
      disablePadding: false,
      label: 'отчество',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.middleName || ''}</Typography>,
    },
    {
      id: 'mainRoleId',
      numeric: false,
      disablePadding: false,
      label: 'роль',
      renderRow: (row) => {
        const role = rolesById[row?.projectSettings?.[currentProject?.uuid]?.mainRoleId];
        return <Typography variant="body2">{role?.name?.ru_RU}</Typography>;
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'статус',
      renderRow: (row) => {
        const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
        const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
        return <UserStatus isAccredited={isAccredited} readyToAccreditate={readyToAccreditate} />;
      },
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'электронная почта',
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'номер телефона',
      renderRow: (row) => <Typography variant="body2">{row.phone}</Typography>,
    },
    {
      id: 'company_name',
      numeric: false,
      disablePadding: false,
      label: 'Название компании',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.companyName}</Typography>,
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'действие',
      hidden: !canAccrediteUser && !canPrintBadge,
      renderRow: (row) => {
        const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
        const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
        // const isAccreditedBy = row.accreditedBy === '';
        const isMerch = row.projectSettings?.[currentProject?.uuid]?.isGivenMerch;
        const isBadgePrinted = row.projectSettings?.[currentProject?.uuid]?.isBadgePrinted;

        const options = [
          // {
          //   label: (
          //     <>
          //       <Groups fontSize="24px" />
          //       {localizeText.SHOW_MEMBERS}
          //     </>
          //   ),
          //   isHidden: !isAccreditedBy,
          //   color: 'error',
          //   onClick: () => {
          //     setSelectedRow(row);
          //     setOpenMemberList(true);
          //   },
          // },
          {
            label: (
              <>
                <Download />
                {localizeText.DOWNLOAD_BADGE}
              </>
            ),
            isHidden: !isAccredited || !canPrintBadge,
            color: 'error',
            onClick: () => {
              eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
                content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
                onOk: () => handleDownloadBadge(row.uuid),
              });
            },
          },
          {
            label: (
              <>
                <Download />
                {localizeText.DOWNLOAD_TICKET}
              </>
            ),
            isHidden: !isAccredited || !canPrintBadge,
            onClick: () => {
              handleDownloadTicket(row.uuid);
            },
          },
          {
            label: (
              <>
                <Check />
                {localizeText.APPROVE}
              </>
            ),
            isHidden: isAccredited || !readyToAccreditate || !canAccrediteUser,
            onClick: () => {
              handleUpdateUser({ userUuid: row.uuid, isAccredited: true });
            },
          },
          {
            label: (
              <>
                <Clear />
                {localizeText.DECLINE}
              </>
            ),
            isHidden: !isAccredited || !canAccrediteUser,
            onClick: () => {
              handleUpdateUser({ userUuid: row.uuid, isAccredited: true });
            },
          },
          {
            label: (
              <>
                {isMerch ? <Clear /> : <Check />}
                {isMerch ? 'Вернуть мерч' : 'Выдать мерч'}
              </>
            ),
            isHidden: !canPrintBadge,
            onClick: async () => {
              await updateUserByProjectUuid(row.uuid, { isGivenMerch: !isMerch });
              onReloadTable();
            },
          },
          {
            label: (
              <>
                {isBadgePrinted ? <Clear /> : <Check />}
                {isBadgePrinted ? 'Вернуть бейдж' : 'Выдать бейдж'}
              </>
            ),
            isHidden: !canPrintBadge,
            onClick: async () => {
              await updateUserByProjectUuid(row.uuid, { isBadgePrinted: !isBadgePrinted });
              onReloadTable();
            },
          },
        ].filter((item) => !item.isHidden);

        return options.length > 0 ? <ActionsButton options={options} /> : null;
      },
    },
  ];

  const handleBulkAction = async (action, selectedRows) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      if (action === 'download_badge') {
        eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
          content: 'При каждой загрузке бейджа, текущий бейдж будет удален. Вы уверены, что продолжите?',
          onOk: async () => {
            try {
              const response = await projectAPIs.massiveActions(projectUuid, { userUuids: selectedRows, action }, true);
              const blobURL = window.URL.createObjectURL(response);
              window.open(blobURL, '_blank').print();
              // eventBus.emit(EVENTS.OPEN_BADGE_FILE_MODAL, {
              //   images: [{ src: blobURL, blob: response }],
              //   onPrint: async () => {
              //     try {
              //       const response = await projectAPIs.massiveActions(
              //         projectUuid,
              //         { userUuids: selectedRows, action: 'print_badge' },
              //         true
              //       );
              //       const url = window.URL.createObjectURL(response);
              //       window.open(url, '_blank').print();
              //     } catch (error) {
              //       createErrorNotification(error.message.error || localizeMessage.ERROR);
              //     }
              //   },
              // });
            } catch (error) {
              createErrorNotification(error.message.error || localizeMessage.ERROR);
            }
          },
        });
        return;
      }
      const isBlobResponse = action === 'download_ticket';
      const response = await projectAPIs.massiveActions(
        projectUuid,
        { userUuids: selectedRows, action },
        isBlobResponse
      );

      if (isBlobResponse) {
        const url = window.URL.createObjectURL(response);
        window.open(url, '_blank');
      } else {
        onReloadTable();
      }
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchScubData = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectAPIs.getScubSetting(projectUuid);
    return res.message;
  };

  const {
    isLoading: isLoadingScub,
    error: scubError,
    // data: scubData,
  } = useQuery(['fetchScubData', projectUuid], fetchScubData, {
    enabled: !!projectUuid,
  });

  const statusCode = scubError ? scubError.code : 200;
  const notFoundScubSettings = statusCode === 404;

  return (
    <Stack>
      <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap" padding="20px">
        <Stack direction="row" gap="8px 24px" flexWrap="wrap" alignItems="center">
          {ability.can(ACCESSES.CREATE_USER) && (
            <Button
              variant="outlined"
              startIcon={<AddIcon sx={{ color: '#9E6AFD' }} />}
              onClick={() => navigate(routes.userCreate.path.replace(':uuid', currentProject?.uuid))}
            >
              добавить участника
            </Button>
          )}
          <Button
            endIcon={showFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            sx={{ '& .MuiButton-endIcon': { margin: 0 } }}
            onClick={handleToggleFilter}
          >
            фильтр
          </Button>
          {projectUuid && !isLoadingScub && notFoundScubSettings && (
            <Link
              href={routes.projectScubSettings.path.replace(':uuid', projectUuid)}
              target="_blank"
              variant="body2"
              sx={{ color: (theme) => theme.palette.warning.main }}
            >
              СКУБ не ставился. Перейдите в настройки
            </Link>
          )}
        </Stack>

        {/* <form noValidate onSubmit={handleSubmit(handleSearch)}>
          <Stack direction="row" gap="8px 24px" flexWrap="wrap">
            <TextField
              name="fullName"
              size="small"
              placeholder="Введите фамилию..."
              sx={{ minWidth: '240px' }}
              {...register('fullName')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: getValues('fullName') ? 'visible' : 'hidden' }}
                    onClick={() => {
                      reset();
                      handleSearch({ fullName: '' });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <Button type="submit" variant="contained">
              поиск
            </Button>
          </Stack>
        </form> */}
      </Stack>

      <Collapse in={showFilter}>
        <Box padding="0 20px" margin="16px 24px">
          <UserFilters onSubmit={handleFilter} />
        </Box>
      </Collapse>

      <MUITable
        id="project-users-table"
        loading={loading || isSubmitting}
        headCells={headCells}
        rows={list}
        rowsNum={counts[tab]}
        selectable={
          [
            accreditationStatuses.processing.name,
            accreditationStatuses.autoGranted.name,
            accreditationStatuses.granted.name,
          ].includes(tab) &&
          (canAccrediteUser || canPrintBadge)
        }
        renderBulkActions={(selectedRows) => (
          <>
            {[accreditationStatuses.processing.name].includes(tab) && canAccrediteUser && (
              <ButtonLoading
                isLoading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => handleBulkAction('approve_accreditation', selectedRows)}
              >
                Подвердить
              </ButtonLoading>
            )}
            {[accreditationStatuses.autoGranted.name, accreditationStatuses.granted.name].includes(tab) && (
              <>
                {canAccrediteUser && (
                  <ButtonLoading
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => handleBulkAction('decline_accreditation', selectedRows)}
                  >
                    отказать
                  </ButtonLoading>
                )}

                {canPrintBadge && (
                  <>
                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('download_badge', selectedRows)}
                    >
                      {localizeText.DOWNLOAD_BADGE}
                    </ButtonLoading>
                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('download_ticket', selectedRows)}
                    >
                      скачать билет
                    </ButtonLoading>

                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('give_merch', selectedRows)}
                    >
                      Выдать мерч
                    </ButtonLoading>

                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('retake_merch', selectedRows)}
                    >
                      Вернуть мерч
                    </ButtonLoading>
                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('issue_badge', selectedRows)}
                    >
                      Выдать бейдж
                    </ButtonLoading>

                    <ButtonLoading
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => handleBulkAction('retake_badge', selectedRows)}
                    >
                      Вернуть бейдж
                    </ButtonLoading>
                  </>
                )}
              </>
            )}
          </>
        )}
        onClickRow={
          ability.can(ACCESSES.SEE_PROFILE)
            ? (row) => navigate(routes.profile.path.replace(':id', row.uuid) + `?projectUuid=${projectUuid}`)
            : undefined
        }
      />
    </Stack>
  );
}

export default UserList;
