import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button, Card, CardContent, Grid, Stack, styled, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomChip from 'src/@core/components/mui/chip';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { request } from 'src/utils/request';
import TemplatesSection from './anketa/TemplatesSection';
import AnketaAddField from './AnketaAddField';
import AnketaCreate from './AnketaCreate';
import AnketaField from './AnketaField';

export const PrimaryMainTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
}));

function AnketaSettings({ projectUuid }) {
  const location = useLocation();

  const [currentRole, setCurrentRole] = useState();
  const [disableAddMore, setDisableAddMore] = useState(false);
  const [showAddNewField, setShowAddNewField] = useState(false);

  const fetchAnketaData = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid] = queryKey;
    const response = await Promise.all([request(`/internal/anketas?projectUuid=${projectUuid}&roleUuid=${roleUuid}`)]);
    return response?.[0]?.message || [];
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchAnketaData,
  } = useQuery(['fetchAnketaData', projectUuid, currentRole], fetchAnketaData, {
    enabled: !!projectUuid && !!currentRole,
  });

  const fetchRoles = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const resRoles = await request(`/roles?projectUuid=${projectUuid}`);
    return resRoles.message || [];
  };

  const [queryRoles] = useQueries([
    { queryKey: ['fetchRoles', projectUuid], queryFn: fetchRoles, enabled: !!projectUuid },
  ]);
  const roles = queryRoles.data;
  const isLoadingRoles = queryRoles.isLoading;

  useEffect(() => {
    const objSearch = qs.parse(location.search);
    if (location?.state?.defaultRoleUuid) {
      setCurrentRole(location?.state?.defaultRoleUuid);
    } else if (objSearch && objSearch.currentRole) {
      setCurrentRole(objSearch.currentRole);
    }
  }, [location]);

  const statusCode = error ? error.code : 200;
  const notFoundAnketa = statusCode === 404;
  const anketaFields = data?.fields || [];
  const ableAddField = projectUuid && currentRole;
  const isDisabledEdit = data && !data.canEdited;

  const handleAddAlreadyField = async (template) => {
    try {
      const numOfFields = data?.fields?.length || 0;
      const position = numOfFields + 1;

      if (numOfFields === 0) {
        // Add anketa
        await request('/internal/anketas/add', {
          method: 'POST',
          body: {
            projectUuid: projectUuid,
            roleUuid: currentRole,
            fields: [{ ...template, fieldTemplateUuid: template.uuid || '', position: 1 }],
          },
        });
      } else {
        // Add anketa field
        await request(`/internal/anketas/${data.uuid}`, {
          method: 'POST',
          body: {
            ...template,
            fieldTemplateUuid: template.uuid || '',
            position: position,
          },
        });
      }
      refetchAnketaData();
    } catch (error) {
      console.error(error);
      toast.error(error.message?.error);
    }
  };

  const handlePatchAnketa = async (body) => {
    try {
      await request(`/internal/anketas/${data.uuid}`, {
        method: 'PATCH',
        body: {
          patchedFields: body,
        },
      });
      refetchAnketaData();
    } catch (error) {
      console.error(error);
      toast.error(error.message?.error);
    }
  };

  const handleMoveFiled = (field, delta) => {
    try {
      const currentPosition = field.position;
      const nextPosition = currentPosition + delta;

      handlePatchAnketa({
        [anketaFields[nextPosition - 1].uuid]: {
          position: currentPosition,
        },
        [field.uuid]: { position: nextPosition },
      });
    } catch (error) {
      console.error(error);
      toast.error(error.message?.error);
    }
  };

  const renderAnketaStatus = (notFoundAnketa, isDuplicate) => {
    let color, label;
    if (notFoundAnketa) {
      color = 'warning';
      label = 'Анкета не создана';
    } else {
      if (isDuplicate) {
        color = 'primary';
        label = 'Продублировано';
      } else {
        color = 'success';
        label = 'Новая анкета';
      }
    }
    return disableAddMore && notFoundAnketa ? (
      <></>
    ) : (
      <CustomChip label={label} skin="light" color={color} sx={{ width: 'fit-content' }} />
    );
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
            Для роли:
          </Typography>
          {isLoadingRoles ? (
            <Loader size="24px" />
          ) : (
            <Select
              fullWidth={true}
              label="Выберите роль"
              options={roles}
              value={currentRole}
              getOptionLabel={(item) => item.name?.ru_RU}
              getOptionValue={(item) => item.id}
              onChange={(value) => setCurrentRole(value)}
            />
          )}
        </Stack>
      </CardContent>

      <Card>
        <CardContent>
          <Box paddingY="42px">
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Stack
                  gap="24px"
                  paddingRight="28px"
                  borderRight={{ xs: 'none', md: '1px solid rgba(58, 53, 65, 0.12)' }}
                >
                  <TemplatesSection
                    ableAddField={ableAddField}
                    disableAddMore={disableAddMore}
                    notFoundAnketa={notFoundAnketa}
                    onAddAlreadyField={handleAddAlreadyField}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack gap="16px">
                  {!currentRole && <p style={{ textAlign: 'center' }}>Выберите роль для создания анкеты</p>}
                  {currentRole &&
                    (isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        <Stack direction="row" flexWrap="wrap" justifyContent="space-between" gap="24px">
                          <Stack gap="9px">
                            <Stack direction="row" gap="24px">
                              <PrimaryMainTypography variant="caption">поля шаблона анкеты</PrimaryMainTypography>
                              {anketaFields?.length > 0 && (
                                <Typography variant="caption">Всего {anketaFields.length} полей</Typography>
                              )}
                            </Stack>
                            {renderAnketaStatus(notFoundAnketa, data?.duplicatedAnketaUuid)}
                          </Stack>

                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ height: 'fit-content' }}
                            color="primary"
                            disabled={disableAddMore && notFoundAnketa}
                            onClick={() => setShowAddNewField(!showAddNewField)}
                          >
                            добавить поле
                          </Button>
                        </Stack>

                        {isDisabledEdit && (
                          <Alert severity="warning">
                            Некоторые пользователи уже используют эту анкету. Так что есть некоторые ограничения при
                            редактировании анкеты
                          </Alert>
                        )}

                        {showAddNewField && (
                          <AnketaAddField
                            projectUuid={projectUuid}
                            roleUuid={currentRole}
                            anketaUuid={data?.uuid}
                            anketaFields={anketaFields}
                            setShowAddNewField={setShowAddNewField}
                            refetchAnketaData={refetchAnketaData}
                          />
                        )}

                        {notFoundAnketa && !showAddNewField ? (
                          <AnketaCreate
                            projectUuid={projectUuid}
                            roleUuid={currentRole}
                            setDisableAddMore={setDisableAddMore}
                            setShowAddNewField={setShowAddNewField}
                            refetchAnketaData={refetchAnketaData}
                          />
                        ) : (
                          <>
                            {(anketaFields || []).map((field) => (
                              <AnketaField
                                key={uniqueId('anketa-field__')}
                                anketaUuid={data.uuid}
                                fieldsLength={anketaFields.length}
                                field={field}
                                isDisabledEdit={isDisabledEdit}
                                refetchAnketaData={refetchAnketaData}
                                onMoveFieldUp={() => handleMoveFiled(field, -1)}
                                onMoveFieldDown={() => handleMoveFiled(field, 1)}
                                onPatchAnketa={handlePatchAnketa}
                              />
                            ))}
                          </>
                        )}
                      </>
                    ))}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Card>
  );
}

export default AnketaSettings;
