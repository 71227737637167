export const routes = {
  login: { path: '/login' },
  manageMeetings: { path: '/meetings' },
  manageMeetingsPage: { path: '/meetings/:id' },
  settings: { path: '/settings' },
  checkMfa: { path: '/login/mfa' },
  settingMfa: { path: '/login/mfa/setting' },
  applications: { path: '/applications' },
  applicationsPage: { path: '/applications/:id' },
  requisition: { path: '/requisition/:status/:id' },
  confirmation: { path: '/confirmation' },
  profile: { path: '/profile/:id' },
  mergeAccounts: { path: '/merge-accounts' },

  projects: { path: '/projects' },
  projectsPage: { path: '/projects/:uuid' },
  projectsMeetings: { path: '/projects/:uuid/meetings' },
  projectVizitDetail: { path: '/projects/:uuid/vizits/:vizitUuid' },
  projectVizits: { path: '/projects/:uuid/vizits' },
  projectVizitCreate: { path: '/projects/:uuid/vizits/create' },
  projectSettings: { path: '/projects/:uuid/settings' },
  projectsUsers: { path: '/projects/:uuid/users' },
  userCreate: { path: '/projects/:uuid/users/create' },
  projectRegisters: { path: '/projects/:uuid/registers' },
  projectRegistersCreate: { path: '/projects/:uuid/registers/create' },
  projectScubSettings: { path: '/projects/:uuid/scub' },
  projectOrders: { path: '/projects/:uuid/orders' },

  myTicket: { path: '/my-ticket/:userUuid/:projectUuid' },
  staffLogin: { path: '/staff/login' },
  staffRegisters: { path: '/staff/registers' },
  users: { path: '/users' },
  userDetail: { path: '/users/:id' },
};

export const isBezviz = (user) => {
  return user?.role === 'bezviz';
};

export const accreditationWithoutProjectUuidStatuses = {
  all: {
    label: 'Все',
    icon: 'mdi:account-outline',
    name: 'all',
    filterQuery: {},
  },
  processing: {
    label: 'на проверке',
    icon: 'mdi:lock-open-outline',
    name: 'processing',
    filterQuery: {
      readyToAccreditate: 'true',
    },
  },
};

export const accreditationStatuses = {
  all: {
    label: 'Все',
    icon: 'mdi:account-outline',
    name: 'all',
    filterQuery: {},
  },
  processing: {
    label: 'на проверке',
    icon: 'mdi:lock-open-outline',
    name: 'processing',
    filterQuery: {
      readyToAccreditate: 'true',
    },
  },
  granted: {
    label: 'Акредитованы вручную',
    icon: 'mdi:bookmark-outline',
    name: 'granted',
    filterQuery: {
      isAccredited: 'true',
      isAutoAccreditated: 'false',
    },
  },
  autoGranted: {
    label: 'Автоакредитованы',
    icon: 'mdi:link',
    name: 'autoGranted',
    filterQuery: {
      isAccredited: 'true',
      isAutoAccreditated: 'true',
    },
  },
  denied: {
    label: 'Отклонено',
    icon: 'mdi:close',
    name: 'denied',
    filterQuery: {
      isAccredited: 'false',
    },
  },
};

export const noVisaStatuses = {
  all: {
    label: 'Все',
    name: 'all',
    filterQuery: {},
  },
  processing: {
    label: 'Ожидает',
    name: 'processing',
    filterQuery: {
      readyToNovisa: 'true',
    },
  },
  granted: {
    label: 'Одобрено',
    name: 'granted',
    filterQuery: {
      noVisa: 'true',
    },
  },
  denied: {
    label: 'Отказ',
    name: 'denied',
    filterQuery: {
      noVisa: 'false',
    },
  },
};

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const DOCUMENT_TYPES = {
  AVATAR: 'photo',
  PASSPORT: 'international_passport',
  CONFIRMATION_LETTER: 'confirmation_letter',
};

export const IMAGE_FILE_TYPES = ['JPG', 'PNG', 'GIF', 'JPEG'];
export const REGEX_E164_PHONE = /^$|^\+[1-9]\d{1,14}$/;
export const REGEX_FULL_NAME = /^[\w\u0400-\u045f]+\s+[\w\u0400-\u045f]+/gi;

export const LOCALIZATION = {
  en_US: 'en_US',
  ru_RU: 'ru_RU',
};

export const COMPANY_TYPE_OPTIONS = {
  LEGAL: {
    [LOCALIZATION.ru_RU]: 'Юридическое лицо',
    [LOCALIZATION.en_US]: 'Legal',
  },
  NONRESIDENT: {
    [LOCALIZATION.ru_RU]: 'Не резидент',
    [LOCALIZATION.en_US]: 'Non-resident',
  },
  BRANCH: {
    [LOCALIZATION.ru_RU]: 'Обособленное подразделение',
    [LOCALIZATION.en_US]: 'Branch',
  },
  PERSON: {
    [LOCALIZATION.ru_RU]: 'Физическое лицо',
    [LOCALIZATION.en_US]: 'Person',
  },
};

export const TEXT = {
  [LOCALIZATION.en_US]: {
    LEFT_MENU_PROJECTS: 'Projects',
    LEFT_MENU_BASKET: 'Baskets',
    SUPPORT_BUTTON: 'Support',
    LOGOUT_BUTTON: 'Log out',
    CHANGE_LANGUAGE_BUTTON: 'Change',
    MAIN_PAGE: 'Main page',
    EVENT_PAGE: 'Event page',
    WELCOME: 'Welcome!',
    JOINED: 'Joined',
    JOIN: 'Join',
    UNTIL_START: 'Start after',
    DAYS: 'days',
    DATE: 'Date',
    MY_TICKET: 'My ticket',
    ORDERS: 'Orders',
    ORDER: 'Order',
    ACCREDITATION: 'Accreditation',
    FULLNAME: 'Fullname',
    FULLNAME_OF_EMPLOYEES: 'Full name',
    DATE_OF_BIRTH: 'Date of birth',
    GENDER: 'Gender',
    CITIZENSHIP: 'Citizenship',
    PASSPORT_NUMBER: 'Passport number',
    ISSUED_BY: 'Issued by',
    SAVE_CHANGES: 'Save changes',
    SHOW_PACKAGE_DETAILS: 'Show package details',
    PURCHASE_SERVICE_PACKAGE: 'Purchase services and packages',
    PLACE_OF_LIVING: 'Address',
    ADD_MEMBER: 'Add member',
    ADD_MEMBER_IN_TAB_ACCREDAITION: 'Add member in tab Accredaition',
    ADD_COMPANY: 'Add company',
    PROFILE: 'Profile',
    PASSPORT_DATA: 'Passport data',
    NUMBER_TELEPHONE: 'Number telephone',
    FROM: 'from',
    ABOUT_THE_EVENT: 'About the event:',
    ARCHIVED_ORDERS: 'Archived orders',
    NO_ORDERS: 'No orders at the moment',
    COMPANY: 'Company',
    NAME_COMPANY: 'Name',
    ADDRESS_COMPANY: 'Address',
    SITE_COMPANY: 'Site',
    CONTACT_PERSON: 'Contact person',
    TYPE_COMPANY: 'Type company',
    INN: 'INN',
    FULLNAME_COMPANY: 'Fullname',
    COUNTRY_COMPANY: 'Country',
    TELEPHONE_COMPANY: 'Telephone',
    EMAIL_COMPANY: 'Email',
    KPP: 'KPP',
    AUTHORIZATION: 'Authorization',
    FORGOT_PASSWORD: 'Forgot your password',
    READY: 'ready',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat Password',
    PROCEED: 'Proceed',
    ALREADY_HAVE_ACCOUNT: 'I already have an account',
    REGISTRATION: 'Registration',
    CREATE_PASSWORD: 'Create a password',
    RESET_PASSWORD: 'Reset the password',
    EVENT: 'Event',
    EVENTS: 'Events',
    EVENTSS: 'Events',
    CHANGE_PASSWORD: 'Change password',
    YOUR_PASSWORD: 'Your password',
    NEW_PASSWORD: 'New password',
    NEW_PASSWORD_REPEAT: 'New password repeat',
    CANCEL: 'Cancel',
    OK: 'OK',
    QUANTITY: 'Quantity',
    TOTAL: 'Total',
    TOTAL_AMOUNT: 'Total amount',
    PAY: 'pay',
    AGREE_WITH_TERMS: 'Agree with the terms',
    TERMS_OF_USE_OF_THE_TRADING_PLATFORM: 'Terms of use of the trading platform',
    NUMBER_ORDER: 'Number',
    IMPOSSIBILITY_OF_RECOGNITION: 'Impossibility of recognition',
    PASSPORT_PROCESSING: 'Passport processing',
    SCANNING_PASSPORT: 'The uploaded data from the passport is being processed',
    WAIT_FEW_SECONDS: 'Please wait a few seconds',
    UPLOAD_SCAN_IDENTITY_DOCUMENT: 'Please upload a scan of an identity document',
    SINGLE_OR_BULK_UPLOAD_SUPPORT: 'Single or bulk upload support',
    UPLOAD_PASSPORT: 'Upload passport',
    UPLOAD_PASSPORT_COMPLETE_DATA: 'Upload a passport to fulfill the fields',
    DURING_SCANNING: 'In process of scanning',
    NO_PROJECTS: 'In the selected period, no projects',
    CONFIRM: 'Confirm',
    PARTICIPATE_PROJECT: 'Do you want to participate in this project?',
    CULTURAL_PROGRAM_AVAILABLE: 'Cultural program available',
    ARE_YOU_SURE: 'Are you sure you want to do this action?',
    CONTINUE: 'Continue',
    RETURN_TO_LIST: 'Return',
    FREE: 'Free',
    HOW_WE_CAN_HELP: 'How we can help?',
    NOT_HAVE_COMPANY: "You don't have company information",
    SCOPE_OF_COMPANY: 'Scope of your company',
    TYPE_ACTIVITY_COMPANY: 'Type of activity of your company',
    COMMENT: 'Comment',
    PURPOSE_VISITING: 'Purpose of visiting the exhibition',
    INTERESTED_SECTIONS: 'What sections/topics of the event are you interested in?',
    NOVELTIES: 'What novelties will be presented at the exhibition?',
    INTERESTED_PROGRAM: 'What event in the business program are you interested in / planning to attend?',
    RECOGNIZE_EVENT_METHOD: 'How did you hear about this event? Where did you see the advertisement?',
    ALLOWED_RECEIVE_NEWS: 'I agree to receive news',
    ALLOWED_PERSONAL_DATA: 'I agree to the processing of personal data',
    JOB_STATUS: 'Your job status',
    EXTENT_COMMERCIAL_DECISIONS: 'To what extent are you empowered to make commercial decisions?',
    AMOUNT_POTENTIAL_PURCHASES: 'Specify the amount of potential purchases at the event',
    EXCHANGE_OF_BUSINESS_CONTACTS: 'Exchange of business contacts',
    MY_BUSINESS_CARD: 'My business card',
    CALENDAR: 'Calendar',
    I_WANT_TO_CHAT: 'I want to chat',
    ABOUT_THE_EXCHANGE: 'About the exchange',
    UPLOAD_LOGO: 'Upload logo',
    FILE_NOT_MORE_15MB: 'The file must be no more than 15 MB',
    COMPANY_NAME: 'Company name',
    REGION: 'Region',
    WEB_ADDRESS: 'Website address',
    FIELD_OF_ACTIVITY: 'Field of activity',
    OFFERED_PRODUCT_OR_SERVICES: 'Offered Products / Services',
    PLEASE_TELL_ABOUT_COMPANY: 'Please tell us about your company',
    MEMBERS: 'Members',
    EMAIL: 'Email',
    JOB_TITLE: 'Position',
    PLEASE_TELL_ABOUT_YOURSELF: 'Please tell us about yourself',
    TABLE_NUMBER: 'Table number',
    TABLE: 'Table',
    CONVENIENT_SLOT: 'Convenient slot',
    INDUSTRY: 'Industry',
    KEYWORDS: 'Keywords',
    NO_DESCRIPTION: 'No description',
    RESET: 'Reset',
    SEARCH: 'Search',
    COMPANIES_DIRECTORY: 'Catalog companies',
    COMPANIES: 'Companies',
    TABLE_NO: 'Table number',
    I_CONFIRM: 'I confirm',
    CONFIRMED_MEETING: 'Confirm your appointment',
    BUSINESS_PROGRAM: 'Business Program',
    TIME: 'Time',
    PROGRAM: 'Program',
    PARTICIPANTS: 'Participants',
    ROOM: 'Room',
    DESCRIPTION: 'Description',
    POSITION: 'Position',
    TYPE: 'Type',
    START: 'Start',
    END: 'End',
    MORE: 'More',
  },
  [LOCALIZATION.ru_RU]: {
    LEFT_MENU_PROJECTS: 'Проекты',
    LEFT_MENU_BASKET: 'Корзина',
    SUPPORT_BUTTON: 'Поддержка',
    LOGOUT_BUTTON: 'Выйти',
    CHANGE_LANGUAGE_BUTTON: 'Изменить',
    MAIN_PAGE: 'Главная страница',
    EVENT_PAGE: 'Страница меропрития',
    WELCOME: 'Добро пожаловать!',
    JOINED: 'Вы участник',
    JOIN: 'Участвовать',
    UNTIL_START: 'До начала осталось',
    DAYS: 'Дней',
    DATE: 'Число',
    MY_TICKET: 'Мой билет',
    ORDERS: 'Заказы',
    ORDER: 'Заказ',
    ACCREDITATION: 'Аккредитация',
    FULLNAME: 'ФИО',
    FULLNAME_OF_EMPLOYEES: 'ФИО сотрудника',
    DATE_OF_BIRTH: 'Дата рождения',
    GENDER: 'Пол',
    CITIZENSHIP: 'Гражданство',
    PASSPORT_NUMBER: 'Серия и номер паспорта',
    ISSUED_BY: 'Кем выдан',
    SAVE_CHANGES: 'Сохранить изменения',
    SHOW_PACKAGE_DETAILS: 'Показать детали пакета',
    PURCHASE_SERVICE_PACKAGE: 'Покупка пакета услуг',
    PLACE_OF_LIVING: 'Адрес',
    ADD_MEMBER: 'Добавить участника',
    ADD_MEMBER_IN_TAB_ACCREDAITION: 'Добавить участника во вкладке Аккредитация',
    ADD_COMPANY: 'Добавить компанию',
    PROFILE: 'Профиль',
    PASSPORT_DATA: 'Паспортные данные',
    NUMBER_TELEPHONE: 'Номер телефона',
    FROM: 'от',
    ABOUT_THE_EVENT: 'О мероприятии:',
    ARCHIVED_ORDERS: 'Архивированные заказы',
    NO_ORDERS: 'Заказы отсутствуют',
    COMPANY: 'Компании',
    NAME_COMPANY: 'Название',
    ADDRESS_COMPANY: 'Адрес',
    SITE_COMPANY: 'Сайт',
    CONTACT_PERSON: 'Контактное лицо',
    TYPE_COMPANY: 'Тип компании',
    INN: 'ИНН',
    FULLNAME_COMPANY: 'Полное название',
    COUNTRY_COMPANY: 'Страна',
    TELEPHONE_COMPANY: 'Телефон',
    EMAIL_COMPANY: 'Почта',
    KPP: 'КПП',
    AUTHORIZATION: 'Авторизация',
    FORGOT_PASSWORD: 'Забыли пароль',
    READY: 'Готово',
    PASSWORD: 'Пароль',
    REPEAT_PASSWORD: 'Повторить пароль',
    PROCEED: 'Продолжить',
    ALREADY_HAVE_ACCOUNT: 'У меня уже есть аккаунт',
    REGISTRATION: 'Регистрация',
    CREATE_PASSWORD: 'Придумайте пароль',
    RESET_PASSWORD: 'Сбросить пароль',
    EVENT: 'событие',
    EVENTSS: 'событий',
    EVENTS: 'события',
    CHANGE_PASSWORD: 'Изменить пароль',
    YOUR_PASSWORD: 'Ваш пароль',
    NEW_PASSWORD: 'Новый пароль',
    NEW_PASSWORD_REPEAT: 'Новый пароль повторить',
    CANCEL: 'Отмена',
    OK: 'Ок',
    QUANTITY: 'Количество',
    TOTAL: 'Итого',
    TOTAL_AMOUNT: 'Общая сумма',
    PAY: 'Оплатить',
    AGREE_WITH_TERMS: 'Согласен с условиями',
    TERMS_OF_USE_OF_THE_TRADING_PLATFORM: 'и правилами пользования торговой площадкой',
    NUMBER_ORDER: 'Номер',
    IMPOSSIBILITY_OF_RECOGNITION: 'Невозможно распознать',
    PASSPORT_PROCESSING: 'Обработка паспорта',
    SCANNING_PASSPORT: 'Загруженные данные с паспорта проходят обработку',
    WAIT_FEW_SECONDS: 'Пожалуйста, подождите несколько секунд',
    UPLOAD_SCAN_IDENTITY_DOCUMENT: 'Пожалуйста, загрузите скан документа, удостоверяющий личность',
    SINGLE_OR_BULK_UPLOAD_SUPPORT: 'Поддержка одиночной или массовой загрузки',
    UPLOAD_PASSPORT: 'Загрузить загранпаспорт',
    UPLOAD_PASSPORT_COMPLETE_DATA: 'Загрузить загранпаспорт для заполнения данных',
    DURING_SCANNING: 'В процессе сканирования',
    NO_PROJECTS: 'В выбранном периоде, нет проектов',
    CONFIRM: 'Подтвердить',
    PARTICIPATE_PROJECT: 'Вы хотите участвовать в этом проекте?',
    CULTURAL_PROGRAM_AVAILABLE: 'Доступна культурная программа',
    ARE_YOU_SURE: 'Вы уверены, что хотите выполнить это действие?',
    CONTINUE: 'Продожить',
    RETURN_TO_LIST: 'Вернуться к списку',
    FREE: 'Бесплатно',
    HOW_WE_CAN_HELP: 'Вопросы которые смогут вам помочь',
    NOT_HAVE_COMPANY: 'У Вас нет информации компании',
    SCOPE_OF_COMPANY: 'Сфера деятельности вашей компании',
    TYPE_ACTIVITY_COMPANY: 'Вид деятельности вашей компании',
    COMMENT: 'Комментарий',
    PURPOSE_VISITING: 'Цель визита на выставку',
    INTERESTED_SECTIONS: 'Какие разделы/тематики мероприятия Вам интересны?',
    NOVELTIES: 'Какие товары/услуги будут представлены на выставке?',
    INTERESTED_PROGRAM: 'Какое мероприятие в деловой программе вам интересны/планируете посетить?',
    RECOGNIZE_EVENT_METHOD: 'Как узнали о данном мероприятии? Где вы видели рекламу?',
    ALLOWED_RECEIVE_NEWS: 'Согласен получать новости',
    ALLOWED_PERSONAL_DATA: 'Согласен на обработку персональных данных',
    JOB_STATUS: 'Ваш должностной статус',
    EXTENT_COMMERCIAL_DECISIONS: 'В какой степени вы наделены полномочиями принимать коммерческие решения?',
    AMOUNT_POTENTIAL_PURCHASES: 'Укажите размер потенциальных закупок на мероприятии',
    EXCHANGE_OF_BUSINESS_CONTACTS: 'Биржа деловых контактов',
    MY_BUSINESS_CARD: 'Моя визитка',
    CALENDAR: 'Календарь',
    I_WANT_TO_CHAT: 'Хочу общаться',
    ABOUT_THE_EXCHANGE: 'О бирже',
    UPLOAD_LOGO: 'Загрузить логотип',
    FILE_NOT_MORE_15MB: 'Файл должен весить не больше 15 Мб',
    COMPANY_NAME: 'Название компании',
    REGION: 'Регион',
    WEB_ADDRESS: 'Адрес сайта',
    FIELD_OF_ACTIVITY: 'Сфера деятельности',
    OFFERED_PRODUCT_OR_SERVICES: 'Предлагаемая продукция / Услуги',
    PLEASE_TELL_ABOUT_COMPANY: 'Пожалуйста, расскажите о вашей компании',
    MEMBERS: 'Участники',
    EMAIL: 'Электронная почта',
    JOB_TITLE: 'Должность',
    PLEASE_TELL_ABOUT_YOURSELF: 'Пожалуйста, расскажите немного о себе',
    TABLE_NUMBER: 'Выберите номер стола',
    TABLE: 'Стол',
    CONVENIENT_SLOT: 'Выберите удобный слот',
    INDUSTRY: 'Отрасль',
    KEYWORDS: 'Ключевые слова',
    NO_DESCRIPTION: 'нет описания',
    RESET: 'Сбросить',
    SEARCH: 'Поиск',
    COMPANIES_DIRECTORY: 'Каталог компаний',
    COMPANIES: 'компаний',
    TABLE_NO: 'Стол №',
    I_CONFIRM: 'Подтверждаю',
    CONFIRMED_MEETING: 'Подтвердите Вашу встречу',
    BUSINESS_PROGRAM: 'Деловая программа',
    TIME: 'Время',
    PROGRAM: 'Программ',
    PARTICIPANTS: 'Участники',
    ROOM: 'Комната',
    DESCRIPTION: 'Описание',
    POSITION: 'Должность',
    TYPE: 'Тип',
    START: 'Начало',
    END: 'Окончание',
    MORE: 'Подробнее',
  },
};

export const MESSAGES = {
  [LOCALIZATION.en_US]: {
    ERROR_MESSAGE_NON_EXIST_EMAIL: 'You have not yet registered in the systems',
    ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL: 'Email did not exist in the system',
    MESSAGE_SENT_PASSWORD_TO_EMAIL: 'Password sent to email',
    PASSWORD_HAS_BEEN_CHANGED: 'Password has been changed',
    YOUR_PASSWORD_NOT_CORRECT: 'Your password is not correct',
    SAVE_SUCCESSFULLY: 'Save successfully',
    AUTHORIZATION_SUCCESSFULLY: 'Authorization successful',
    EMAIL_OR_PASSWORD_INCORRECT: 'Email or password is incorrect, please try again',
    COMPANY_ADDED: 'Company added',
    ADDED_TO_CART: 'Added to cart',
    LEAVE_SUCCESS: 'Leave success',
    SUCCESSFUL_PARTICIPATION: 'Successful Participation',
    SUCCESSFUL_JOIN: 'Successful Join',
    PAYMENT_PROCESS_FAIL: 'Payment processing failed',
    ERROR: 'Error',
    DO_NOT_HAVE_TICKET: "You don't have a ticket yet",
    WRONG_DATA: 'Wrong data',
    EMAIL_EXIST: 'Email exists',
    ALREADY_REGISTERED: 'User already registered',
    ALREADY_JOINED_PROJECT: 'User has already joined this project',
    FORCE_EDIT_CONFIRM:
      'You are accredited. You will lose all current accreditations if you want to edit your profile. After editing, you must re-accredit. Are you sure you want to edit your profile?',
    ARE_YOU_SURE: 'Are you sure to do this?',
    PLEASE_UPLOAD_LOGO: 'Please upload logo',
    PLEASE_UPLOAD_AVATAR: 'Please upload avatar',
    CHOOSE_MEETING_SLOT: 'Choose a meeting slot',
    AVAILABLE_FOR_MEETING: 'Available for meeting',
    TIME_AND_PLACE_OF_MEETING: 'Your time and place of meeting',
    CONFIRMED_MEETING: 'Your meeting has been confirmed',
    SHOW_ALL_PARTICIPANTS: 'Show all participants',
    SLOT_BUSY_ERROR: 'The slot was busy. Choose another',
    DO_NOT_HAVE_APPOINTMENTS: "You don't have scheduled appointments",
    DO_NOT_HAVE_USER_VIZIT: "You don't have user vizit",
  },
  [LOCALIZATION.ru_RU]: {
    PASSWORD_HAS_BEEN_CHANGED: 'Пароль изменен',
    YOUR_PASSWORD_NOT_CORRECT: 'Ваш пароль не верный',
    SAVE_SUCCESSFULLY: 'Сохранено',
    AUTHORIZATION_SUCCESSFULLY: 'Авторизация успешна',
    EMAIL_OR_PASSWORD_INCORRECT: 'электронная почта или пароль неверны, пожалуйста, попробуйте еще раз',
    COMPANY_ADDED: 'Добавлена компания',
    ADDED_TO_CART: 'Добавлено в корзину',
    LEAVE_SUCCESS: 'Сохранено',
    SUCCESSFUL_PARTICIPATION: 'Вы участник',
    SUCCESSFUL_JOIN: 'Успешное присоединение',
    PAYMENT_PROCESS_FAIL: 'Обработка платежа не удалась',
    ERROR: 'Ошибка',
    DO_NOT_HAVE_TICKET: 'У вас пока нет билета',
    WRONG_DATA: 'Неверные данные',
    EMAIL_EXIST: 'Электронная почта существует',
    ALREADY_REGISTERED: 'Пользователь уже зарегистрирован',
    ALREADY_JOINED_PROJECT: 'Пользователь уже присоединился к этому проекту',
    ERROR_MESSAGE_NON_EXIST_EMAIL: 'Вы не зарегистрированы в системе',
    ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL: 'Указаная почта не найдена',
    MESSAGE_SENT_PASSWORD_TO_EMAIL: 'Пароль отправлен на почту',
    FORCE_EDIT_CONFIRM:
      'Вы аккредитованы. Если вы хотите отредактировать данные вашего профиля, вы потеряете все текущие аккредитации. После редактирования необходимо заново акредитоваться. Вы уверены что хотите изменить профиль?',
    ARE_YOU_SURE: 'Вы уверены, что сделаете это?',
    PLEASE_UPLOAD_LOGO: 'Пожалуйста, загрузите логотип',
    PLEASE_UPLOAD_AVATAR: 'Пожалуйста, загрузите аватар',
    CHOOSE_MEETING_SLOT: 'Выберите слот для встреч',
    AVAILABLE_FOR_MEETING: 'Доступен для встречи',
    TIME_AND_PLACE_OF_MEETING: 'Ваше время и место встречи',
    CONFIRMED_MEETING: 'Ваша встреча была подвержденна',
    SHOW_ALL_PARTICIPANTS: 'Показать всех участников',
    SLOT_BUSY_ERROR: 'Слот был занят. Выбрай другой',
    DO_NOT_HAVE_APPOINTMENTS: 'У вас нету запланированных встреч',
    DO_NOT_HAVE_USER_VIZIT: 'У вас нет визита пользователя',
  },
};
