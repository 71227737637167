import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import { routes } from 'src/constants';
import { useProjectDetail } from 'src/hooks';
import { useAbility } from 'src/hooks/useAbility';
import UserIcon from 'src/layouts/components/UserIcon';
import UserLayout from 'src/layouts/UserLayout';
import { getRegistersList, setProject } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { ORDER_DIRECTION } from 'src/utils/constant';
import UserFilters from './UserFilters';
import UserList from './UserList';

function ProjectRegistersPage() {
  const TABS = [
    {
      key: 'fullName',
      label: 'Фамилия',
      icon: <UserIcon icon="mdi:account-outline" />,
    },
    {
      key: 'companyName',
      label: 'Компания',
      icon: <UserIcon icon="mdi:account-multiple" />,
    },
    {
      key: 'phone',
      label: 'Номер телефона',
      icon: <UserIcon icon="mdi:cellphone" />,
    },
    {
      key: 'passportNumber',
      label: 'Номер паспорта',
      icon: <UserIcon icon="mdi:card-account-details-outline" />,
    },
  ];

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbility();

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isInitTotal, setIsInitTotal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [sortState, setSortState] = useState({
    sortOrder: ORDER_DIRECTION.ASC,
    sortBy: '',
  });

  const user = useSelector((state) => state.auth.user);
  const currentProject = useSelector((state) => state.application.currentProject);
  const counts = useSelector((state) => state.application.counts);

  const projectUuid = params.uuid;
  const queryParams = qs.parse(location.search);
  const activeTab = queryParams.tab || TABS[0].key;

  const { isLoading, data } = useProjectDetail({ projectUuid: projectUuid });

  const handleGetApplicationList = useCallback(() => {
    return getRegistersList({
      ...queryParams,
      limit: queryParams.limit,
      page: queryParams.page,
      sortOrder: (queryParams.sortOrder ?? '').toUpperCase(),
    });
  }, [isInitTotal, location, currentProject]);

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projectRegisters.path.replace(':uuid', projectUuid)}?${query}`);
    },
    [queryParams]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
    }
  }, [isLoading, data]);

  const handleSelectTab = (name) => () => {
    setQueryParams({ tab: name, page: 1 });
  };

  useEffect(() => {
    // if (isInitTotal) {
    setIsLoadingTable(true);
    handleGetApplicationList().then(() => setIsLoadingTable(false));
    // }
  }, [handleGetApplicationList]);

  const handleSearch = (searchText) => {
    setIsLoadingTable(true);
    setQueryParams({ tab: activeTab, page: 1, query: searchText });
    // handleGetApplicationList(searchText).then(() => setIsLoadingTable(false));
  };

  const handleFilter = (filters) => {
    setIsLoadingTable(true);
    setQueryParams({ ...filters, page: 1 });
  };

  const handleRequestSort = (property) => {
    const { sortBy, sortOrder } = sortState;
    const isAsc = sortBy === property && sortOrder === ORDER_DIRECTION.ASC;
    const isDesc = sortBy === property && sortOrder === ORDER_DIRECTION.DESC;
    setSortState({
      sortOrder: isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC,
      sortBy: property,
    });
  };

  return (
    <UserLayout hideSidebar={true} allowCopyBadgeID={true}>
      <Box>
        <Stack gap="20px" alignItems="center">
          <Grid container>
            <Grid item xs={12} md={2}>
              <IconButton onClick={() => navigate(routes.projectsPage.path.replace(':uuid', projectUuid))}>
                <ChevronLeft />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" align="center">
                Регистрация
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>

          {ability.can(ACCESSES.CREATE_USER) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate(routes.projectRegistersCreate.path.replace(':uuid', projectUuid))}
            >
              добавить посетителя +
            </Button>
          )}

          <UserFilters onSubmit={handleFilter} />
          {/* <Stack direction="row" gap="20px" flexWrap="wrap" justifyContent="center">
            {TABS.map((tab) => (
              <Button
                variant={activeTab === tab.key ? 'contained' : 'text'}
                color={activeTab === tab.key ? 'primary' : 'secondary'}
                key={tab.key}
                startIcon={tab.icon}
                endIcon={<KeyboardArrowDownIcon />}
                className={`${classes.tabButton} ${activeTab === tab.key ? classes.activatedTab : ''}`}
                onClick={handleSelectTab(tab.key)}
              >
                {tab.label}
              </Button>
            ))}
          </Stack>

          <UserSearchBar activeTab={activeTab} onSearchUser={handleSearch} /> */}

          <UserList
            loading={isLoadingTable}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            sortState={sortState}
            setSortState={setSortState}
            onRequestSort={handleRequestSort}
            onReloadTable={() => {
              setIsLoadingTable(true);
              handleGetApplicationList().then(() => setIsLoadingTable(false));
            }}
          />
        </Stack>
      </Box>

      <ConfirmModal />
    </UserLayout>
  );
}

export default ProjectRegistersPage;
